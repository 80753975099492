import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

//this is code reuse, Ignore Tank, instead of borehole
function BoreholesDetails() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [selectedTank, setSelectedTank] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchBoreholes = async () => {
      try {
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        const agency_code = userString ? JSON.parse(userString).agency_code : "User";

        if (!agency_code || !token) {
          console.log("Agency code or token not found in localStorage");
          return;
        }

        setProgress(50);

        const response = await axios.get(`http://41.59.227.123:55005/api/boreholes`, {
          params: {
            agency_code: agency_code,
            token: token,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });

        setData(response.data);
        setProgress(100);
      } catch (error) {
        console.error('Error fetching data: ', error);
        setProgress(100);
      }
    };

    fetchBoreholes();
  }, []);

  const handleViewDetails = (tank) => {
    setSelectedTank(tank);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTank(null);
  };

  const handleSearch = async () => {
    try {
      const token = localStorage.getItem('token');
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      const search_type = document.getElementById('search_type').value.trim();

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      if ( !search_type) 
      {
        // If any field is empty, return an error
        Swal.fire({
          icon: 'error',
          title: 'Field Empty',
          text: 'Fill all the fields',
        });

        return false;
      }

      setProgress(50);

      const response = await axios.get(`http://41.59.227.123:55005/api/search_borehole`, {
        params: {
          agency_code: agency_code,
          token: token,
          search_type:search_type
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      setData(response.data);
      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setProgress(100);
    }
  };

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-4">
                <p className="h3 text-primary">Registered Boreholes</p>
              </div>
              <div className="col-lg-5">
                <form className="form-inline my-2 my-lg-0">
                  <input className="form-control mr-sm-2" type="search" id="search_type" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-outline-primary my-2 my-sm-0" onClick={handleSearch} type="button">Search</button>
                </form>
              </div>
              <div className="col-lg-3">
                <Link className="btn btn-md btn-success" to="/admins/boreholes-map">Display on the Map</Link>
              </div>
            </div>
          </div>
          <div className="row">
            {data && data.length > 0 ? (
            data.map((item, index) => (
              <div key={index} className="col-md-2 mb-5 card m-2 p-2">
                <div className="row">
                  <div className="col-4 text-primary">
                    <span className="fa fa-building fa-4x" aria-hidden="true"></span>
                  </div>
                  <div className="col-8">
                    <p>{item.borehole_name}</p>
                    <button className="btn btn-sm btn-primary" onClick={() => handleViewDetails(item)}>
                      <span className="fa fa-eye"></span> See Details
                    </button>
                  </div>
                </div>
              </div>
            ))
          )
         : (
           <div className="col-md-12 text-center">
             <p>No data available</p>
           </div>
         )}
          </div>
          {selectedTank && (
            <div className={`modal ${showModal ? "show" : ""}`} tabIndex="-1" role="dialog" style={{ display: showModal ? "block" : "none" }}>
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Tank Details</h5>
                    <button type="button" className="close" onClick={handleCloseModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Jina la Kisima: {selectedTank.borehole_name}</p>
                    <p>Urefu wa Kisima: {selectedTank.borehole_depth} Meters</p>
                    <p>Uwezo wa Kisima: {selectedTank.borehole_capacity}</p>
                    <p>Mwaka kilipochimbwa: {selectedTank.installation_year}</p>
                    <p>Maeneo linapohudumia: {selectedTank.borehole_saving_area}</p>
                    <p>Muda wa Ufanyaji Kazi: {selectedTank.borehole_operation_hrs}</p>
                    <p>Hali ya Kisima: {selectedTank.borehole_status_name}</p>
                    <p>Wilaya: {selectedTank.district_name}</p>
                    <p>Zonal: {selectedTank.zonal_name}</p>
                    <p>Kata: {selectedTank.ward_name}</p>
                    <p>Mtaa: {selectedTank.street_name}</p>
                    <p>Tank Lilipo:  <a href={`https://www.google.com/maps/place/${selectedTank.location}`} target="_blank" rel="noopener noreferrer">
                      {selectedTank.location}
                    </a></p>
                    {/* Add more details here */}
                  </div>
                  <div className="modal-footer">
                    <Link to={`/admins/borehole-flash/${selectedTank.id}/${selectedTank.borehole_name}`} className="btn btn-success"><span className="fa fa-eye"></span> View Flashing Records</Link>
                    <Link to={`/admins/borehole-edit/${selectedTank.id}`} className="btn btn-primary">Edit Borehole Details</Link>
                    <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BoreholesDetails;
