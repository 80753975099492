import React, { useState, useEffect } from "react"; 
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Button, Modal, Form } from "react-bootstrap";
import Swal from 'sweetalert2';

function ZonalData() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const token = localStorage.getItem('token');
  const [showModal, setShowModal] = useState(false);
  const [newZoneName, setNewZoneName] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [RegionOptions, setRegionOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const userString = localStorage.getItem('user');
  let isAdmin = false;

  if (userString) {
  const user = JSON.parse(userString);
  isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
  }

  useEffect(() => {
    fetchZones();
  }, [currentPage]);

  useEffect(() => {
    fetchRegions();
  }, []);

  const fetchZones = async () => {
    try {
      setProgress(50);
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      const response = await axios.get(`http://41.59.227.123:55005/api/listed_zones`, {
        params: {
          agency_code: agency_code,
          token: token,
          page: currentPage,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      setData(response.data.data);
      setTotalPages(response.data.totalPages);
      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setProgress(100);
    }
  };

  //this function fetches the listed districts
  const fetchRegions = async () => {
    try {
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      const response = await axios.get('http://41.59.227.123:55005/api/listed_districts', {
        params: {
          agency_code: agency_code,
          token: token
        },
        headers: {
          "Content-Type": "application/json",
        }
      });
      setRegionOptions(response.data.data);
    } catch (error) {
      console.error('Error fetching zonal data: ', error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const addNew = () => {
    setIsEditing(false);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setNewZoneName("");
    setSelectedRegion("");
  };

  const handleEdit = (zone) => {
    setIsEditing(true);
    setEditId(zone.id);
    setNewZoneName(zone.name);
    setSelectedRegion(zone.district_id); // Assuming region_id is part of the zone data
    setShowModal(true);
  };

  const handleNewZoneSubmit = async () => {
    try {
      const newZoneName = document.getElementById('newZoneName').value.trim();
      const region = document.getElementById('region').value.trim();
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      if (!newZoneName || !region) {
        Swal.fire({
          icon: 'error',
          title: 'Field Empty',
          text: 'Fill all the fields',
        });
        return;
      }

      if (isEditing) {
        // Edit existing zone
        await axios.put(`http://41.59.227.123:55005/api/edit_zone/${editId}`, {
          name: newZoneName,
          region_id: region,
          token, 
          agency_code,
          editId,
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Zone updated successfully!',
        });
      } else {
        // Add new zone
        await axios.post(`http://41.59.227.123:55005/api/add_zone`, {
          name: newZoneName,
          region_id: region,
          token, 
          agency_code
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Zone added successfully!',
        });
      }

      setShowModal(false);
      setNewZoneName("");
      setSelectedRegion("");
      // Refetch zones after update
      fetchZones();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Sorry! an error occurred',
      });
    }
  };

  const handleDeleteData = (item) => {
    console.log(item);
    // Display confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this Detail. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform deletion operation with the item ID
        axios.delete('http://41.59.227.123:55005/api/delete-zone', { data: { item: item, token: token } })
          .then(response => {
            // Handle success
            // Show success message
            Swal.fire({
              title: 'Deleted!',
              text: 'The data has been deleted.',
              icon: 'success'
            });
            // Update UI by removing the deleted zone from data state
            fetchZones(); // Refresh zones after deletion
          })
          .catch(error => {
            // Handle error
            console.error('Error deleting zone:', error);
            // Show error message
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete the zone.',
              icon: 'error'
            });
          });
      }
    });
  };

  const handleSearch = async () => {
    try {
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      const search_type = document.getElementById('search_type').value.trim();

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      if (!search_type) {
        Swal.fire({
          icon: 'error',
          title: 'Field Empty',
          text: 'Fill all the fields',
        });
        return;
      }

      setProgress(50);

      const response = await axios.get(`http://41.59.227.123:55005/api/search_zone`, {
        params: {
          agency_code: agency_code,
          token: token,
          search_type: search_type
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.length === 0) {
        setData([]);
        Swal.fire({
          icon: 'info',
          title: 'Not Found',
          text: 'No zones found',
        });
      } else {
        setData(response.data);
      }

      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setProgress(100);
    }
  };

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-4">
                <p className="h3 text-primary">Listed Zones</p>
              </div>
              <div className="col-lg-5">
                <form className="form-inline my-2 my-lg-0">
                  <input className="form-control mr-sm-2" type="search" id="search_type" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-outline-primary my-2 my-sm-0" onClick={handleSearch} type="button">Search</button>
                </form>
              </div>
              <div className="col-lg-3">
                <Button className="btn btn-md btn-success" onClick={addNew} disabled={!isAdmin}>
                  <span className="fa fa-plus"></span> Add new Record
                </Button>
              </div>
            </div>
          </div>
          {data.length === 0 ? (
            <center>
              <p className="h4">No Zones registered</p>
            </center>
          ) : (
            <div className="col-lg-10 mb-2">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">Zone</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((record, index) => (
                    <tr key={index}>
                      <td>{record.name}</td>
                      <td>
                      {/* Edit button */}
                      <span
                        className="fa fa-edit text-primary"
                        onClick={() => isAdmin && handleEdit(record)}
                        style={{ cursor: isAdmin ? 'pointer' : 'not-allowed', opacity: isAdmin ? 1 : 0.5 }}
                      ></span>

                      &nbsp;&nbsp;

                      {/* Delete button */}
                      <span
                        className="fa fa-trash text-danger"
                        onClick={() => isAdmin && handleDeleteData(record.id)}
                        style={{ cursor: isAdmin ? 'pointer' : 'not-allowed', opacity: isAdmin ? 1 : 0.5 }}
                      ></span>
                    </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-3">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>

                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {/* Modal for adding new zone */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit Zone" : "Add New Zone"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formRegion">
              <Form.Label>District</Form.Label>
              <Form.Control
                as="select"
                id="region"
                value={selectedRegion}
                onChange={(e) => setSelectedRegion(e.target.value)}
                required
              >
                <option value="">Select District</option>
                {RegionOptions.map(option => (
                  <option key={option.id} value={option.id}>{option.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formZoneName">
              <Form.Label>Zone Name</Form.Label>
              <Form.Control
                type="text"
                id="newZoneName"
                placeholder="Enter zone name"
                value={newZoneName}
                onChange={(e) => setNewZoneName(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleNewZoneSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ZonalData;
