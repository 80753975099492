import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

function MeterReplacement() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      setProgress(50);

      const response = await axios.get("http://41.59.227.123:55005/api/meter_replacement", {
        params: {
          agency_code: agency_code,
          token: token,
          page: currentPage,
          limit: 30, // Ensure limit is passed to the backend
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const fetchedData = response.data.data;
      setData(fetchedData);

      const totalCount = parseInt(response.data.totalRecords, 10);
      if (!isNaN(totalCount) && totalCount > 0) {
        setTotalPages(Math.ceil(totalCount / 30));
      } else {
        setTotalPages(1);
      }

      setLoading(false);
      setProgress(100);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
      setProgress(0);
    }
  };

  const handleSearch = () => {
    if (fromDate && toDate) {
      navigate(`/admins/filtered-meter-replaced/${fromDate}/${toDate}`);
    } else {
      Swal.fire("Please select both dates", "", "warning");
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5;

    if (totalPages <= maxPageNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li key={i} className={`page-item ${currentPage === i ? "active" : ""}`}>
            <button className="page-link" onClick={() => handlePageChange(i)}>
              {i}
            </button>
          </li>
        );
      }
    } else {
      const startPages = Math.min(3, totalPages);
      const endPages = Math.max(totalPages - 2, totalPages - 2);
      const middlePagesStart = Math.max(3, currentPage - 1);
      const middlePagesEnd = Math.min(totalPages - 2, currentPage + 1);

      for (let i = 1; i <= startPages; i++) {
        pageNumbers.push(
          <li key={i} className={`page-item ${currentPage === i ? "active" : ""}`}>
            <button className="page-link" onClick={() => handlePageChange(i)}>
              {i}
            </button>
          </li>
        );
      }

      if (middlePagesStart > 4) {
        pageNumbers.push(
          <li key="start-ellipsis" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
      }

      for (let i = middlePagesStart; i <= middlePagesEnd; i++) {
        pageNumbers.push(
          <li key={i} className={`page-item ${currentPage === i ? "active" : ""}`}>
            <button className="page-link" onClick={() => handlePageChange(i)}>
              {i}
            </button>
          </li>
        );
      }

      if (middlePagesEnd < totalPages - 3) {
        pageNumbers.push(
          <li key="end-ellipsis" className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        );
      }

      for (let i = endPages; i <= totalPages; i++) {
        pageNumbers.push(
          <li key={i} className={`page-item ${currentPage === i ? "active" : ""}`}>
            <button className="page-link" onClick={() => handlePageChange(i)}>
              {i}
            </button>
          </li>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className="content-wrapper">
      <LoadingBar color='#29D' progress={progress} onLoaderFinished={() => setProgress(0)} />
      <div className="col-12 mx-auto">
        <div className="col-lg-12 mt-3 mb-3 p-2 card">
          <div className="row">
            <div className="col-md-7">
              <p className="text-primary h4">
                <strong>Meter replacement history: Filter by Dates</strong>
              </p>
            </div>
            <div className="col-md-5">
              <form className="form-inline my-2 my-lg-0">
                <input
                  className="form-control mr-sm-2"
                  type="date"
                  id="from_date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                to &nbsp;
                <input
                  className="form-control mr-sm-2"
                  type="date"
                  id="to_date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
                <button className="btn btn-outline-primary my-2 my-sm-0" type="button" onClick={handleSearch}>
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
        {loading ? (
          <center>Loading...</center>
        ) : (
          <div className="card mt-3">
            <div className="card-body">
              {!data || data.length === 0 ? (
                <p>No data found</p>
              ) : (
                <>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Customer Name</th>
                        <th>Account Number</th>
                        <th>Phone Number</th>
                        <th>Replacement Count</th>
                        <th>View Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td>{item.first_name} {item.middle_name} {item.sur_name}</td>
                          <td>{item.account_number}</td>
                          <td>{item.phone_number}</td>
                          <td className="text-danger">{item.replacement_count}</td>
                          <td className="text-primary"><Link to={`/admins/meter-replacement-history/${item.account_number}`}><span className="fa fa-eye"></span></Link></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <nav>
                    <ul className="pagination">
                      <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                          Previous
                        </button>
                      </li>
                      {renderPageNumbers()}
                      <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MeterReplacement;
