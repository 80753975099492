import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function EditWaterQualityStation() {
    const { station_id } = useParams();
    const [progress, setProgress] = useState(0);
    const [zonalOptions, setZonalOptions] = useState([]);
    const [selectedZonal, setSelectedZonal] = useState('');
    const [wards, setWards] = useState([]);
    const [selectedWardId, setSelectedWardId] = useState('');
    const [streets, setStreets] = useState([]);
    const [selectedStreetId, setSelectedStreetId] = useState('');
    const [stationDetails, setStationDetails] = useState({});
    const [stationTypes, setStationTypes] = useState([]);
    const [selectedStationType, setSelectedStationType] = useState('');
    const [noData, setNoData] = useState(false);
    const userString = localStorage.getItem('user');
  let isAdmin = false;

        if (userString) {
        const user = JSON.parse(userString);
        isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
        }

    useEffect(() => {
        const fetchZonalData = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('http://41.59.227.123:55005/api/get_zones', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setZonalOptions(response.data);
            } catch (error) {
                console.error('Error fetching zonal data: ', error);
            }
        };

        fetchZonalData();
    }, []);

    useEffect(() => {
        const fetchStationTypes = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";

                const response = await axios.get('http://41.59.227.123:55005/api/get_station_type', {
                    params: {
                        agency_code: agency_code,
                        token: token
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                setStationTypes(response.data);
                if (response.data.length > 0) {
                    setSelectedStationType(response.data[0].id);
                }
            } catch (error) {
                console.error('Error fetching station types data: ', error);
            }
        };

        fetchStationTypes();
    }, []);

    const getSelectedWards = async (selectedZonalId) => {
        try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            const response = await axios.get(`http://41.59.227.123:55005/api/get_wards`, {
                params: {
                    agency_code: agency_code,
                    token: token,
                    selectedZonalId: selectedZonalId
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });
            setWards(response.data);
        } catch (error) {
            console.error('Error fetching wards data: ', error);
        }
    };

    const fetchStreets = async (selectedWardId) => {
        try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            const response = await axios.get(`http://41.59.227.123:55005/api/get_streets`, {
                params: {
                    agency_code: agency_code,
                    token: token,
                    selectedWardId: selectedWardId
                },
                headers: {
                    "Content-Type": "application/json",
                }
            });
            setStreets(response.data);
        } catch (error) {
            console.error('Error fetching streets data: ', error);
        }
    };

    useEffect(() => {
        setProgress(50);
        const fetchStationDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";
                const response = await axios.get(`http://41.59.227.123:55005/api/get_station_details/${station_id}`, {
                    params: {
                        agency_code: agency_code,
                        token: token,
                        station_id: station_id
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                const stationData = response.data[0];

                if (stationData) {
                    setSelectedStationType(stationData.station_type_id);
                    setStationDetails(stationData);
                    setNoData(false);
                    setSelectedZonal(stationData.zonal_id);
                    getSelectedWards(stationData.zonal_id);
                    setSelectedWardId(stationData.ward_id);
                    fetchStreets(stationData.ward_id);
                    setSelectedStreetId(stationData.street_id);
                } else {
                    setNoData(true);
                }
                setProgress(100);
            } catch (error) {
                setNoData(true);
                setProgress(100);
                console.error('Error fetching station details: ', error);
            }
        };
        fetchStationDetails();
    }, [station_id]);

    const updateStationDetails = async () => {
        try {
            const station_name = document.getElementById('station_name').value.trim();
            const station_type = document.getElementById('station_type').value.trim();
            const zonal = selectedZonal;
            const ward = selectedWardId;
            const street = selectedStreetId;
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";

            if (!station_name || !station_type || !zonal || !ward || !street) {
                Swal.fire({
                    icon: 'error',
                    title: 'Field Empty',
                    text: 'Fill all the fields',
                });

                return false;
            }

            await axios.put(`http://41.59.227.123:55005/api/update-station-details/${station_id}`, {
                station_name,
                station_type,
                zonal,
                ward,
                street,
                token,
                agency_code,
                station_id
            });

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Station details updated successfully!',
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Sorry! an error occurred',
            });
        }
    };

    const deleteStation = () => {
        const token = localStorage.getItem('token');
        // Display confirmation dialog
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this station. This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform deletion operation with the station_id
                axios.delete('http://41.59.227.123:55005/api/DeletewaterQualityStation', { data: { station_id: station_id, token: token } })
                    .then(response => {
                        // Handle success
                        // Show success message
                        Swal.fire({
                            title: 'Deleted!',
                            text: 'The Station has been deleted.',
                            icon: 'success'
                        }).then(() => {
                            // Redirect to the desired URL
                            window.location.href = '/admins/water-Quality-stations';
                        });
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deleting station:', error);
                        // Show error message
                        Swal.fire({
                            title: 'Error!',
                            text: 'Failed to delete the station.',
                            icon: 'error'
                        });
                    });
            }
        });
    };
    return (
        <div>
            <LoadingBar
                progress={progress}
                color='#29D'
                onLoaderFinished={() => setProgress(0)}
            />
            <div className="content-wrapper">
                <div className="col-12 mx-auto">
                    <div className="modal-body col-8">
                        {noData ? (
                            <div className="text-center text-danger">No Data</div>
                        ) : (
                            <>
                                <div className="form-group">
                                    <label htmlFor="station_name">Station Name</label>
                                    <input type="text" className="form-control" id="station_name" defaultValue={stationDetails.station_name} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="station_type">Station Type</label>
                                    <select
                                        id="station_type"
                                        className="form-control"
                                        value={selectedStationType}
                                        onChange={(e) => setSelectedStationType(e.target.value)}
                                    >
                                        {stationTypes.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="zonalSelect">Zonal</label>
                                    <select
                                        id="zonalSelect"
                                        className="form-control"
                                        value={selectedZonal}
                                        onChange={(event) => {
                                            const zonalId = event.target.value;
                                            setSelectedZonal(zonalId);
                                            getSelectedWards(zonalId);
                                        }}
                                    >
                                        <option value="">Select Zonal</option>
                                        {zonalOptions.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="wardSelect">Ward</label>
                                    <select
                                        id="wardSelect"
                                        className="form-control"
                                        value={selectedWardId}
                                        onChange={(event) => {
                                            const wardId = event.target.value;
                                            setSelectedWardId(wardId);
                                            fetchStreets(wardId);
                                        }}
                                    >
                                        <option value="">Select Ward</option>
                                        {wards.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="streetSelect">Street</label>
                                    <select
                                        id="streetSelect"
                                        className="form-control"
                                        value={selectedStreetId}
                                        onChange={(event) => setSelectedStreetId(event.target.value)}
                                    >
                                        <option value="">Select Street</option>
                                        {streets.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="modal-footer">
                                <button type="submit" onClick={updateStationDetails} className="btn btn-md btn-success" disabled={!isAdmin}>Update Station Details</button>
                                    <button  type="submit" onClick={deleteStation} className="btn btn-md btn-danger" disabled={!isAdmin}>Delete Station</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditWaterQualityStation;
