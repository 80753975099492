import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'; // Import SweetAlert

function ReportLeakages() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem("token");
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  const zone = userString ? JSON.parse(userString).zonal_id : "User";
  const [zonalOptions, setZonalOptions] = useState([]);
  const [selectedZonal, setSelectedZonal] = useState('');
  const [wards, setWards] = useState([]);
  const [selectedWardId, setSelectedWardId] = useState('');
  const [streets, setStreets] = useState([]);
  const [selectedStreetId, setSelectedStreetId] = useState('');
  const [customerStatus, setCustomerStatus] = useState([]); 

  // New states for form inputs
  const [reporterPhoneNumber, setReporterPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [selectedFaultType, setSelectedFaultType] = useState('');
  const username = userString ? JSON.parse(userString).name : "User";
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      
      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      setProgress(50);

      const response = await axios.get("http://41.59.227.123:55005/api/get_reported_leakage_listing", {
        params: {
          agency_code: agency_code,
          page: currentPage,
          token: token,
          zone: zone,
          limit: 30, // Ensure limit is passed to the backend
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Response Data:", response.data); // Debug: log the response data

      const fetchedData = response.data.faults || [];
      setData(fetchedData);

      const totalCount = response.data.totalPages || 1;
      setTotalPages(totalCount);

      setLoading(false);
      setProgress(100);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
      setProgress(0);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Uncleared faults':
        return 'text-danger';
      case 'Cleared faults':
        return 'text-success';
      case 'Faults in progress':
        return 'text-warning';
      default:
        return '';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchFaultType = async () => {
      try {
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        const agency_code = userString ? JSON.parse(userString).agency_code : "User";

        const response = await axios.get('http://41.59.227.123:55005/api/get_service_provided', {
          params: {
            agency_code: agency_code,
            token: token
          },
          headers: {
            "Content-Type": "application/json",
          }
        }); 
        setCustomerStatus(response.data);
      } catch (error) {
        console.error('Error fetching fault type data: ', error);
      }
    };

    fetchFaultType();
  }, []);

  useEffect(() => {
    const fetchZonalData = async () => {
      try {
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        const agency_code = userString ? JSON.parse(userString).agency_code : "User";

        const response = await axios.get('http://41.59.227.123:55005/api/get_zones', {
          params: {
            agency_code: agency_code,
            token: token
          },
          headers: {
            "Content-Type": "application/json",
          }
        }); 
        setZonalOptions(response.data);
      } catch (error) {
        console.error('Error fetching zonal data: ', error);
      }
    };

    fetchZonalData();
  }, []);

  const getSelectedWards = async (event) => {
    const selectedZonalId = event.target.value;
    setSelectedZonal(selectedZonalId);

    try {
      const token = localStorage.getItem('token');
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      const response = await axios.get(`http://41.59.227.123:55005/api/get_wards`, {
        params: {
          agency_code: agency_code,
          token: token,
          selectedZonalId: selectedZonalId
        },
        headers: {
          "Content-Type": "application/json",
        }
      }); 
      setWards(response.data);
    } catch (error) {
      console.error('Error fetching wards data: ', error);
    }
  };

  const fetchStreets = async (event) => {
    const selectedWardId = event.target.value;
    setSelectedWardId(selectedWardId);
    try {
      const token = localStorage.getItem('token');
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      const response = await axios.get(`http://41.59.227.123:55005/api/get_streets`, {
        params: {
          agency_code: agency_code,
          token: token,
          selectedWardId: selectedWardId
        },
        headers: {
          "Content-Type": "application/json",
        }
      }); 
      setStreets(response.data);
    } catch (error) {
      console.error('Error fetching streets data: ', error);
    }
  };

  const handleReportLeakage = async () => {
    if (!selectedZonal || !selectedWardId || !selectedStreetId || !selectedFaultType || !reporterPhoneNumber || !description) {
      Swal.fire({
        icon: 'warning',
        title: 'Incomplete Form',
        text: 'Please fill in all fields before submitting.',
      });
      return;
    }

    try {
      const response = await axios.post("http://41.59.227.123:55005/api/admin_report_leakage", {
        agency_code: agency_code,
        zonal_id: selectedZonal,
        ward_id: selectedWardId,
        street_id: selectedStreetId,
        fault_type: selectedFaultType,
        reporter_phone_number: reporterPhoneNumber,
        description: description,
        token: token,
        username: username,
      }, // Send data as JSON object
      {
        headers: {
          "Content-Type": "application/json", // Set Content-Type header
        }
    });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Leakage Reported',
          text: 'Leakage reported successfully!',
        });
        // Reset form fields
        setSelectedZonal('');
        setSelectedWardId('');
        setSelectedStreetId('');
        setSelectedFaultType('');
        setReporterPhoneNumber('');
        setDescription('');
        fetchData();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Submission Failed',
          text: 'Failed to report leakage. Please try again.',
        });
      }
    } catch (error) {
      console.error("Error reporting leakage: ", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error reporting leakage. Please try again.',
      });
    }
  };

  return (
    <div className="content-wrapper">
      <div className="col-lg-12 mx-auto col-11">
        <LoadingBar color='#29D' progress={progress} onLoaderFinished={() => setProgress(0)} />

        <div className="card col-11 mt-3">
          <div className="col-12 pt-2">
            <h2 className="card-title text-primary ">Report Leakage </h2>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="zonalSelect" className="mb-1 p-1">Select Zonal</label>
                <select
                  id="zonalSelect"
                  className="form-control"
                  value={selectedZonal}
                  onChange={getSelectedWards}
                >
                  <option value="">Select Zonal</option>
                  {zonalOptions.map(option => (
                    <option key={option.id} value={option.id}>{option.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="wardSelect" className="mb-1 p-1">Select Wards</label>
                <select
                  id="wardSelect"
                  className="form-control"
                  value={selectedWardId}
                  onChange={fetchStreets}
                >
                  <option value="">Select Ward</option>
                  {wards.map(option => (
                    <option key={option.id} value={option.id}>{option.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="streetSelect" className="mb-1 p-1">Select Street</label>
                <select
                  id="streetSelect"
                  className="form-control"
                  value={selectedStreetId}
                  onChange={(event) => setSelectedStreetId(event.target.value)}
                >
                  <option value="">Select Streets</option>
                  {streets.map(option => (
                    <option key={option.id} value={option.id}>{option.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="faultTypeSelect" className="mb-1 p-1">Select Fault Type</label>
                <select
                  id="faultTypeSelect"
                  className="form-control"
                  value={selectedFaultType}
                  onChange={(event) => setSelectedFaultType(event.target.value)}
                >
                  <option value="">Select Fault Type</option>
                  {customerStatus.map(option => (
                    <option key={option.id} value={option.id}>{option.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="reporterPhoneNumber" className="mb-1 p-1">Enter Reporter Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="reporterPhoneNumber"
                  placeholder="Enter Reporter Phone Number"
                  value={reporterPhoneNumber}
                  onChange={(event) => setReporterPhoneNumber(event.target.value)}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="description" className="mb-1 p-1">Enter Description</label>
                <textarea
                  className="form-control"
                  id="description"
                  style={{ height: '150px' }}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="col-lg-3">
              <button className="btn btn-primary mt-5" onClick={handleReportLeakage}>Report Leakage</button>
            </div>
          </div>
        </div>

        <div className="col-lg-11 mt-3 mb-3 p-2 card">
          <div className="mb-4"></div>
          {loading ? (
            <center>Loading...</center>
          ) : (
            <>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Reported Date</th>
                    <th>Fault Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length === 0 ? (
                    <tr>
                      <td colSpan="10" className="text-center text-danger h4">No data found</td>
                    </tr>
                  ) : (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{formatDate(item.created_at)}</td>
                        <td>{item.service_type_name}</td>
                        <td className={getStatusClass(item.description)}>{item.description}</td>
                        <td className="text-primary"><Link to={`/admins/get-leakage-admins/${item.id}`} className="btn btn-md btn-primary">View</Link></td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <nav className="d-flex justify-content-center mt-3">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li className={`page-item ${index + 1 === currentPage ? 'active' : ''}`} key={index}>
                      <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                  </li>
                </ul>
              </nav>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportLeakages;
