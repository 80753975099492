import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function CurrentTankLevels() {
  const [progress, setProgress] = useState(0);
  const [tanks, setTanks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem('token');
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";

  useEffect(() => {
    fetchTanks();
  }, [agency_code, token, page]);

  const fetchTanks = async () => {
    try {
      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      setProgress(50);

      const response = await axios.get(`http://41.59.227.123:55005/api/Current_level_tanks`, {
        params: {
          agency_code: agency_code,
          token: token,
          page: page
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      setTanks(response.data.data);
      setTotalPages(response.data.totalPages || 1); // Assuming the backend sends totalPages
      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setProgress(100);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          {tanks.length === 0 ? (
            <div className="text-center mt-5">
              <h3>No connected tanks</h3>
            </div>
          ) : (
            <div className="d-flex flex-wrap">
              {tanks.map((tank, index) => {
                const latestLevel = tank.tank_levels.length > 0 ? tank.tank_levels[0] : null;
                const levelValue = latestLevel ? latestLevel.value : 0;

                return (
                  <div key={index} className="col-lg-6 col-md-6 col-sm-12 mt-3 mb-3 p-2 card">
                    <h3 className="text-primary">{tank.tank_name}</h3>
                    <p><strong>Tank Capacity:</strong> {tank.tank_capacity}</p>
                    <div className="mt-4">
                      <h5>Current Water Level</h5>
                      <div className="chart-container" style={{ position: 'relative', height: '300px', width: '100%' }}>
                        <Bar 
                          data={{
                            labels: ['Current Water Level'],
                            datasets: [
                              {
                                label: 'Water Level',
                                data: [levelValue],
                                backgroundColor: 'rgba(0, 123, 255, 0.3)', // Water-like color
                                borderColor: 'rgba(0, 123, 255, 1)',
                                borderWidth: 1,
                                borderRadius: { topLeft: 0, topRight: 0, bottomLeft: 50, bottomRight: 50 }, // Flat top, rounded bottom
                                borderSkipped: false,
                              }
                            ]
                          }}
                          options={{
                            indexAxis: 'x', // This makes the bar chart vertical (water level)
                            scales: {
                              y: {
                                max: tank.tank_capacity,
                                title: {
                                  display: true,
                                  text: 'Water Level (units)',
                                }
                              },
                              x: {
                                title: {
                                  display: true,
                                  text: 'Tank',
                                }
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5>Latest Water Level Reading: 
                        {latestLevel ? `${latestLevel.value} (Recorded on ${new Date(latestLevel.created_at).toLocaleString()})` : 'N/A'}
                      </h5>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page - 1)}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  className={`page-item ${index + 1 === page ? 'active' : ''}`}
                  key={index}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default CurrentTankLevels;
