import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Link } from "react-router-dom";


//this is code reuse, Ignore Tank, instead of water Appurtenance
function WaterAppurtenance() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [selectedTank, setSelectedTank] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchBoreholes = async () => {
      try {
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        const agency_code = userString ? JSON.parse(userString).agency_code : "User";

        if (!agency_code || !token) {
          console.log("Agency code or token not found in localStorage");
          return;
        }

        setProgress(50);

        const response = await axios.get(`http://41.59.227.123:55005/api/fitting_types`, {
          params: {
            agency_code: agency_code,
            token: token,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });

        setData(response.data);
        setProgress(100);
      } catch (error) {
        console.error('Error fetching data: ', error);
        setProgress(100);
      }
    };

    fetchBoreholes();
  }, []);

  const handleViewDetails = (tank) => {
    setSelectedTank(tank);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTank(null);
  };

 

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-4">
                <p className="h3 text-primary">Registered Appurtenances</p>
              </div>
              <div className="col-lg-5">
               
              </div>
              <div className="col-lg-3">
               
              </div>
            </div>
          </div>
          <div className="row">
            {data && data.length > 0 ? (
            data.map((item, index) => (
              <div key={index} className="col-md-2 mb-5 card m-2 p-2">
                <div className="col-md-10 mx-auto">
                    <p className="text-dark"><strong>{item.name} <span className="text-primary">({item.total_fittings})</span> </strong></p>
                    <div class="d-grid gap-2">
                    <Link to={`/admins/appurtenances-map/${item.id}/${item.name}`} className="btn  btn-sm btn-primary"  type="button"><i className="fa fa-eye"></i> View on the Map</Link>
                    </div>
                </div>
              </div>
            ))
          )
         : (
           <div className="col-md-12 text-center">
             <p>No data available</p>
           </div>
         )}
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default WaterAppurtenance;
