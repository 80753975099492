import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import Swal from 'sweetalert2';

function ConnectTanks() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [selectedTank, setSelectedTank] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [telnetType, setTelnetType] = useState('');
  const [telnetUsername, setTelnetUsername] = useState('');
  const [telnetPassword, setTelnetPassword] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [tankCapacity, setTankCapacity] = useState(''); // Added state for TankCapacity
  const token = localStorage.getItem('token');
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  let isAdmin = false;

if (userString) {
  const user = JSON.parse(userString);
  isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
}

  const fetchTanks = async () => {
    try {
      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      setProgress(50);

      const response = await axios.get(`http://41.59.227.123:55005/api/registered_tanks_connected`, {
        params: {
          agency_code: agency_code,
          token: token,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      setData(response.data);
      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setProgress(100);
    }
  };

  useEffect(() => {
    fetchTanks();
  }, [agency_code, token]);

  const handleViewDetails = (tank) => {
    setSelectedTank(tank);
    setTelnetType(tank.telnet_type || '');
    setTelnetUsername(tank.telnet_username || '');
    setTelnetPassword(tank.telnet_password || '');
    setMobileNo(tank.mobile_no || '');
    setTankCapacity(tank.tank_capacity || ''); // Set TankCapacity
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTank(null);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!agency_code || !token) {
      console.log("Agency code or token not found in localStorage");
      return;
    }

    if (!telnetType || !telnetUsername || !telnetPassword || !mobileNo || !tankCapacity) {
      Swal.fire({
        icon: 'error',
        title: 'Field Empty',
        text: 'Fill all the fields',
      });
      return;
    }

    try {
      setProgress(50);

      await axios.post(`http://41.59.227.123:55005/api/tank_connect`, {
        agency_code: agency_code,
        token: token,
        tank_id: selectedTank.tank_id,
        telnet_type: telnetType,
        telnet_username: telnetUsername,
        telnet_password: telnetPassword,
        mobile_no: mobileNo,
        tank_capacity: parseInt(tankCapacity, 10), // Include TankCapacity in the request
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Tank connection details saved successfully!',
      });
      fetchTanks(); // Reload the page
      handleCloseModal(); // Close the modal
    } catch (error) {
      console.error('Error saving data: ', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while saving data',
      });
      setProgress(100);
    }
  };

  const handleSearch = async () => {
    try {
      const search_type = document.getElementById('search_type').value.trim();

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      if (!search_type) {
        Swal.fire({
          icon: 'error',
          title: 'Field Empty',
          text: 'Fill all the fields',
        });
        return;
      }

      setProgress(50);

      const response = await axios.get(`http://41.59.227.123:55005/api/search_tank_connect`, {
        params: {
          agency_code: agency_code,
          token: token,
          search_type: search_type
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      setData(response.data);
      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setProgress(100);
    }
  };

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-4">
                <p className="h3 text-primary">Connect Water Tanks</p>
              </div>
              <div className="col-lg-5">
                <form className="form-inline my-2 my-lg-0">
                  <input className="form-control mr-sm-2" type="search" id="search_type" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-outline-primary my-2 my-sm-0" onClick={handleSearch} type="button">Search</button>
                </form>
              </div>
              <div className="col-lg-3">
                {/* Additional buttons or filters can be added here */}
              </div>
            </div>
          </div>
          <div className="row">
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <div key={index} className={`col-md-2 mb-5 card m-2 p-2`}>
                  <div className="row">
                    <div className={`col-4 ${item.tank_status_id === 1 ? 'text-primary' : item.tank_connect === 'yes' ? 'text-success' : 'text-danger'}`}>
                      <span className="fa fa-building fa-4x" aria-hidden="true"></span>
                    </div>
                    <div className="col-8">
                      <p>{item.tank_name}</p>
                      <button className="btn btn-sm btn-primary" onClick={() => handleViewDetails(item)}>
                        <span className="fa fa-eye"></span> Connect Tank
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-12 text-center">
                <p>No data available</p>
              </div>
            )}
          </div>
          {selectedTank && (
            <div className={`modal ${showModal ? "show" : ""}`} tabIndex="-1" role="dialog" style={{ display: showModal ? "block" : "none" }}>
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Tank Details</h5>
                    <button type="button" className="close" onClick={handleCloseModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={handleFormSubmit}>
                      <div className="form-group">
                        <label htmlFor="telnetType">Telnet Type</label>
                        <input
                          type="text"
                          className="form-control"
                          id="telnetType"
                          value={telnetType}
                          onChange={(e) => setTelnetType(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="telnetUsername">Telnet Username</label>
                        <input
                          type="text"
                          className="form-control"
                          id="telnetUsername"
                          value={telnetUsername}
                          onChange={(e) => setTelnetUsername(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="telnetPassword">Telnet Password</label>
                        <input
                          type="text"
                          className="form-control"
                          id="telnetPassword"
                          value={telnetPassword}
                          onChange={(e) => setTelnetPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="mobileNo">Mobile No</label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobileNo"
                          value={mobileNo}
                          onChange={(e) => setMobileNo(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="tankCapacity">Tank Capacity</label> {/* Added input for TankCapacity */}
                        <input
                          type="number"
                          className="form-control"
                          id="tankCapacity"
                          value={tankCapacity}
                          onChange={(e) => setTankCapacity(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="btn btn-primary" disabled={!isAdmin} >Save Details</button>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                  </div>
              </div>
            </div>
            </div>
          )}
        </div>
    </div>
    </div>
  );
}

export default ConnectTanks;
