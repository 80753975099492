import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import Swal from "sweetalert2";

function DataCollectorData() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const token = localStorage.getItem("token");
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);

  useEffect(() => {
    fetchData();
    fetchLoggedInUser();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setProgress(30);
      const params = {
        page: currentPage,
        token: token,
        agency_code: agency_code,
      };

      // If search is active, include the date range in the params
      if (isSearchActive) {
        params.start_date = startDate;
        params.end_date = endDate;
      }

      const response = await axios.get(isSearchActive ? "http://41.59.227.123:55005/api/search-data-collectors-data" : "http://41.59.227.123:55005/api/data-collectors-data", {
        params: params
      });
      
      if (response.data && response.data.data) {
        setData(response.data.data);
        setTotalPages(response.data.totalPages);
      } else {
        setData([]);
        setTotalPages(1);
      }
      
      setProgress(100);
    } catch (error) {
      console.error("Error fetching data:", error);
      setProgress(0);
    }
  };

  const fetchLoggedInUser = async () => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      setLoggedInUser(user);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();

    if (!startDate.trim() || !endDate.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Both start and end dates are required.',
      });
      return;
    }

    setIsSearchActive(true); // Mark search as active
    setCurrentPage(1); // Reset to the first page of the search results

    try {
      setProgress(30);
      const response = await axios.get("http://41.59.227.123:55005/api/search-data-collectors-data", {
        params: { page: 1, token: token, agency_code: agency_code, start_date: startDate, end_date: endDate }
      });
      
      if (response.data && response.data.data) {
        setData(response.data.data);
        setTotalPages(response.data.totalPages);
      } else {
        setData([]);
        setTotalPages(1);
      }
      
      setProgress(100);
    } catch (error) {
      console.error("Error fetching data:", error);
      setProgress(0);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
    setSelectedUser(null);
  };

  return (
    <div>
      <LoadingBar progress={progress} color="#29D" onLoaderFinished={() => setProgress(0)} />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-4">
                <p className="h3 text-primary">Select Dates</p>
              </div>
              <div className="col-lg-8">
                <form className="form-inline my-2 my-lg-0" onSubmit={handleSearch}>
                  <input
                    className="form-control mr-sm-2"
                    type="date"
                    id="start_date"
                    placeholder="Start Date"
                    aria-label="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  &nbsp; &nbsp;
                  to
                  &nbsp; &nbsp;
                  <input
                    className="form-control mr-sm-2"
                    type="date"
                    id="end_date"
                    placeholder="End Date"
                    aria-label="End Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  <button className="btn btn-outline-primary my-2 my-sm-0" type="submit">Search</button>
                </form>
              </div>
            </div>
          </div>
          {data.length === 0 ? (
            <center>
              <p className="h4">No users registered</p>
            </center>
          ) : (
            <div className="col-lg-10 mb-2">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Code</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Data Collected</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((user, index) => (
                    <tr key={index}>
                      <td>{user.full_name}</td>
                      <td>{user.phone_number}</td>
                      <td>{user.code}</td>
                      <td>{user.gender}</td>
                      <td>{user.total_data_collected}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-3">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li className={`page-item ${index + 1 === currentPage ? 'active' : ''}`} key={index}>
                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataCollectorData;
