import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from 'react-router-dom';
import UnclearedStatusAdmins from './unclearedLeakageAdmin';
import ClearedStatusAdmins from './clearedLeakageAdmins';
import OnProgressStatusAdmin from './OnProgressLeakageAdmins';

function LeakageAdmins() {
  const [data, setData] = useState(null);
  const [agencyCode, setAgencyCode] = useState('');
  const token = localStorage.getItem("token");
  const { leakage_id } = useParams();
  const userString = localStorage.getItem('user');
  let isAdmin = false;

if (userString) {
  const user = JSON.parse(userString);
  isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
}

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      setAgencyCode(user.agency_code);
      fetchData(user.agency_code, token);
    }
  }, [token]);

  const fetchData = async (agencyCode, token) => {
    try {
      if (!agencyCode || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      const response = await axios.get("http://41.59.227.123:55005/api/get_leakage_by_admins", {
        params: {
          agency_code: agencyCode,
          token: token,
          leakage_id: leakage_id
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const fetchedData = response.data;
      console.log("Received Fault Status:", fetchedData.fault_status); // Log the fault status
      setData(fetchedData);

      console.log("Customer Data:", fetchedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setData(null);
    }
  };

  const getStatusDetails = (status) => {
    switch (status) {
      case '0':
      case 0:
        return { className: "bg-danger", statusText: "Uncleared" };
      case '1':
      case 1:
        return { className: "bg-success", statusText: "Cleared" };
      case '2':
      case 2:
        return { className: "bg-warning", statusText: "On Progress" };
      default:
        return { className: "bg-info", statusText: "Unknown" };
    }
  };

  const renderStatusContent = (status, data) => {
    const numericStatus = Number(status); // Convert status to a number

    if (numericStatus === 0) {
      return <UnclearedStatusAdmins />;
    } else if (numericStatus === 1) {
      return <ClearedStatusAdmins data={data} />;
    } else if (numericStatus === 2) {
      return <OnProgressStatusAdmin data={data} />; // Ensure return keyword is here
    } else {
      return (
        <div className="bg-info">
          <center>Unknown Fault Status</center>
        </div>
      );
    }
  };

  const handleDeleteData  = (faultID) => {
    const token = localStorage.getItem('token');
    // Display confirmation dialog
    console.log(faultID);
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this Fault. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform deletion operation with the customerId
        axios.delete('http://41.59.227.123:55005/api/delete-fault', { data: { faultID: faultID, token:token } })
          .then(response => {
            // Handle success
            // Show success message
            Swal.fire({
              title: 'Deleted!',
              text: 'The data has been deleted.',
              icon: 'success'
            });
            // Update UI by removing the deleted customer from data state
            //fetchWaterSources();
            window.location.href = '/admins/faults-summary';
          })
          .catch(error => {
            // Handle error
            console.error('Error deleting customer:', error);
            // Show error message
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete the customer.',
              icon: 'error'
            });
          });
      }
    });
  };

  return (
    <div className="col-lg-8 mx-auto col-11">
      <div className="col-lg-12 mt-3 mb-3 p-2 card">
        {!data ? (
          <div className="col-12 text-center">
            <p className="text-danger h4">No data found</p>
          </div>
        ) : (
          <>
            <div className={`small-box ${getStatusDetails(data.fault_status).className}`}>
              <p className="text-center h2 p-2">
                <strong>Reported Date:</strong> {new Date(data.created_at).toLocaleDateString()} &nbsp;&nbsp;
                <strong>Status:</strong> {getStatusDetails(data.fault_status).statusText}
              </p> 
            </div>
            <h5 className="pt-2">Faults Details</h5>
            <p><strong>Reporter Phone Number:</strong> {data.phone_number}</p>
            <p><strong>District:</strong> {data.district_name}</p>
            <p><strong>Zone:</strong> {data.zone_name}</p>
            <p><strong>Ward:</strong> {data.ward_name}</p>
            <p><strong>Street:</strong> {data.street_name}</p>
            <p><strong>Location:</strong><a href={`https://www.google.com/maps/place/${encodeURIComponent(data.location)}`} target="_blank" rel="noopener noreferrer">{data.location}</a></p>
            <div className="col-12 bg-light p-2">
              <p className="border-bottom h5">Description</p>
              <p>{data.description}</p>
              <button className="btn btn-lg btn-danger float-end" onClick={() => handleDeleteData(data.id)} disabled={!isAdmin} >Delete Fault</button>
            </div>
            <div className="mb-4"></div>
            {renderStatusContent(data.fault_status, data)}
          </>
        )}
      </div>
    </div>
  );
}

export default LeakageAdmins;
