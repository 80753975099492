import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { Link } from "react-router-dom";

function LeakageZoneListing() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem("token");
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  const zone = userString ? JSON.parse(userString).zonal_id : "User";

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      setProgress(50);

      const response = await axios.get("http://41.59.227.123:55005/api/get_reported_leakage_listing_zone", {
        params: {
          agency_code: agency_code,
          page: currentPage,
          token: token,
          zone: zone,
          limit: 30, // Ensure limit is passed to the backend
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Response Data:", response.data); // Debug: log the response data

      const fetchedData = response.data.faults || [];
      setData(fetchedData);

      const totalCount = response.data.totalPages || 1;
      setTotalPages(totalCount);

      setLoading(false);
      setProgress(100);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
      setProgress(0);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Uncleared faults':
        return 'text-danger';
      case 'Cleared faults':
        return 'text-success';
      case 'Faults in progress':
        return 'text-warning';
      default:
        return '';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="col-lg-8 mx-auto col-11">
      <LoadingBar color='#29D' progress={progress} onLoaderFinished={() => setProgress(0)} />
      <div className="col-lg-12 mt-3 mb-3 p-2 card">
        <div className="mb-4"></div>
        {loading ? (
          <center>Loading...</center>
        ) : (
          <>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Reported Date</th>
                  <th>Fault Type</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 ? (
                  <tr>
                    <td colSpan="10" className="text-center text-danger h4">No data found</td>
                  </tr>
                ) : (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>{formatDate(item.created_at)}</td>
                      <td>{item.service_type_name}</td>
                      <td className={getStatusClass(item.description)}>{item.description}</td>
                      <td className="text-primary"><Link to={`/admins/get-leakage-technician/${zone}/${item.id}`} className="btn btn-md btn-primary">View</Link></td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <nav className="d-flex justify-content-center mt-3">
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li className={`page-item ${index + 1 === currentPage ? 'active' : ''}`} key={index}>
                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                </li>
              </ul>
            </nav>
          </>
        )}
      </div>
    </div>
  );
}

export default LeakageZoneListing;
