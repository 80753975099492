import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';

function CustomerDetail() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [zonalOptions, setZonalOptions] = useState([]);
  const [selectedZonal, setSelectedZonal] = useState('');
  const [wards, setWards] = useState([]);
  const [selectedWardId, setSelectedWardId] = useState('');
  const [streets, setStreets] = useState([]);
  const [selectedStreetId, setSelectedStreetId] = useState('');
  const [customerStatus, setCustomerStatus] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [customerProblems, setCustomerProblems] = useState([]);
  const [serviceProvided, setServiceProvided] = useState([]);
  const [tatizoMiundombinu, setTatizoMiundombinu] = useState([]);
  const userString = localStorage.getItem('user');
  let isAdmin = false;

if (userString) {
  const user = JSON.parse(userString);
  isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
}

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "";

      if (!agency_code || !token) {
        console.error("Agency code or token not found in localStorage");
        return;
      }

      setProgress(50);

      let url = `http://41.59.227.123:55005/api/customer_search?agency_code=${agency_code}&token=${token}&page=${currentPage}&limit=30`;

      if (selectedZonal) url += `&zonalSelect=${selectedZonal}`;
      if (selectedWardId) url += `&wardSelect=${selectedWardId}`;
      if (selectedStreetId) url += `&streetSelect=${selectedStreetId}`;
      
      const customer_name_search = document.getElementById('customer_name_search').value;
      const connection_status = document.getElementById('connection_status').value;
      const connection_types = document.getElementById('connection_types').value;
      const customer_problem = document.getElementById('customer_problem').value;
      const service_provided = document.getElementById('service_provided').value;
      const tatizo_miundombinu = document.getElementById('tatizo_miundombinu').value;

      if (customer_name_search) url += `&customer_name_search=${customer_name_search}`;
      if (connection_status) url += `&connection_status=${connection_status}`;
      if (connection_types) url += `&connection_types=${connection_types}`;
      if (customer_problem) url += `&customer_problem=${customer_problem}`;
      if (service_provided) url += `&service_provided=${service_provided}`;
      if (tatizo_miundombinu) url += `&tatizo_miundombinu=${tatizo_miundombinu}`;

      const response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });

      setData(response.data.data || []);
      setTotalPages(response.data.totalPages || 0);
      setCurrentPage(response.data.currentPage || 1);
      setLoading(false);
      setProgress(100);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      setProgress(100);
    }
  };

  const fetchOptionsData = async (url, setData) => {
    try {
      const token = localStorage.getItem('token');
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "";

      const response = await axios.get(url, {
        params: { agency_code: agency_code, token: token },
        headers: { "Content-Type": "application/json" }
      });

      setData(response.data || []);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  useEffect(() => {
    fetchOptionsData('http://41.59.227.123:55005/api/get_zones', setZonalOptions);
    fetchOptionsData('http://41.59.227.123:55005/api/get_connection_status', setCustomerStatus);
    fetchOptionsData('http://41.59.227.123:55005/api/get_customer_type', setCustomerTypes);
    fetchOptionsData('http://41.59.227.123:55005/api/get_customer_problems', setCustomerProblems);
    fetchOptionsData('http://41.59.227.123:55005/api/get_service_provided', setServiceProvided);
    fetchOptionsData('http://41.59.227.123:55005/api/matatizo_miundoMbinu', setTatizoMiundombinu);
  }, []);

  const getSelectedWards = async (event) => {
    setSelectedZonal(event.target.value);
    fetchOptionsData(`http://41.59.227.123:55005/api/get_wards?selectedZonalId=${event.target.value}`, setWards);
  };

  const fetchStreets = async (event) => {
    setSelectedWardId(event.target.value);
    fetchOptionsData(`http://41.59.227.123:55005/api/get_streets?selectedWardId=${event.target.value}`, setStreets);
  };

  const handleSearch = async () => {
    setCurrentPage(1);
    fetchData();
  };

  const Download = async () => {
    try {
        setDownloading(true);
        const token = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        const agency_code = userString ? JSON.parse(userString).agency_code : "";

        let url = `http://41.59.227.123:55005/api/download_customer_data?agency_code=${agency_code}&token=${token}`;

        if (selectedZonal) url += `&zonalSelect=${selectedZonal}`;
        if (selectedWardId) url += `&wardSelect=${selectedWardId}`;
        if (selectedStreetId) url += `&streetSelect=${selectedStreetId}`;
        
        const customer_name_search = document.getElementById('customer_name_search').value;
        const connection_status = document.getElementById('connection_status').value;
        const connection_types = document.getElementById('connection_types').value;
        const customer_problem = document.getElementById('customer_problem').value;
        const service_provided = document.getElementById('service_provided').value;
        const tatizo_miundombinu = document.getElementById('tatizo_miundombinu').value;

        if (customer_name_search) url += `&customer_name_search=${customer_name_search}`;
        if (connection_status) url += `&connection_status=${connection_status}`;
        if (connection_types) url += `&connection_types=${connection_types}`;
        if (customer_problem) url += `&customer_problem=${customer_problem}`;
        if (service_provided) url += `&service_provided=${service_provided}`;
        if (tatizo_miundombinu) url += `&tatizo_miundombinu=${tatizo_miundombinu}`;

        const response = await axios.get(url, {
            headers: { "Content-Type": "application/json" },
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: 'text/csv' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'customer_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);

        setDownloading(false);
        setProgress(100);
    } catch (error) {
        console.error('Error downloading customer data:', error);
        setDownloading(false);
    }
  };

  const handleDeleteCustomer = (customerId) => {
    const token = localStorage.getItem('token');
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this customer. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete('http://41.59.227.123:55005/api/delete-customer', { data: { customerId: customerId, token: token } })
          .then(() => {
            Swal.fire('Deleted!', 'The customer has been deleted.', 'success');
            setData(prevData => prevData.filter(customer => customer.id !== customerId));
          })
          .catch(error => {
            console.error('Error deleting customer:', error);
            Swal.fire('Error!', 'Failed to delete the customer.', 'error');
          });
      }
    });
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    const halfWay = Math.ceil(maxPageNumbersToShow / 2);
    let startPage = currentPage > halfWay ? Math.max(currentPage - halfWay, 1) : 1;
    let endPage = currentPage > halfWay ? Math.min(currentPage + halfWay - 1, totalPages) : Math.min(maxPageNumbersToShow, totalPages);

    if (startPage > 1) {
      pageNumbers.push(
        <li key={1} className={`page-item ${1 === currentPage ? 'active' : ''}`}>
          <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
        </li>
      );
      if (startPage > 2) {
        pageNumbers.push(<li key="ellipsis-start" className="page-item disabled"><span className="page-link">...</span></li>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <button className="page-link" onClick={() => setCurrentPage(i)}>{i}</button>
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<li key="ellipsis-end" className="page-item disabled"><span className="page-link">...</span></li>);
      }
      pageNumbers.push(
        <li key={totalPages} className={`page-item ${totalPages === currentPage ? 'active' : ''}`}>
          <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      <LoadingBar progress={progress} color='#29D' onLoaderFinished={() => setProgress(0)} />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="customer_name_search" className="mb-1 p-1">Filter by Name|Meter #|A/c #|Phone #</label>
                  <input type="text" className="form-control" id="customer_name_search" name="customer_name_search" />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="zonalSelect" className="mb-1 p-1">Filter by Zonal</label>
                  <select id="zonalSelect" className="form-control" value={selectedZonal} onChange={getSelectedWards}>
                    <option value="">Select Zonal</option>
                    {zonalOptions.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="wardSelect" className="mb-1 p-1">Filter by Wards</label>
                  <select id="wardSelect" className="form-control" value={selectedWardId} onChange={fetchStreets}>
                    <option value="">Select Ward</option>
                    {wards.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="streetSelect" className="mb-1 p-1">Filter by Streets</label>
                  <select id="streetSelect" className="form-control" value={selectedStreetId} onChange={(event) => setSelectedStreetId(event.target.value)}>
                    <option value="">Select Streets</option>
                    {streets.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="connection_status" className="mb-1 p-1">Filter by Connection Status</label>
                  <select id="connection_status" className="form-control">
                    <option value="">Connection Status</option>
                    {customerStatus.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="connection_types" className="mb-1 p-1">Filter by Customer Category</label>
                  <select id="connection_types" className="form-control">
                    <option value="">Customer Category</option>
                    {customerTypes.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="customer_problem" className="mb-1 p-1">Filter by Customer Problems</label>
                  <select id="customer_problem" className="form-control">
                    <option value="">Customer Problems</option>
                    {customerProblems.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="service_provided" className="mb-1 p-1">Filter by Service Provided</label>
                  <select id="service_provided" className="form-control">
                    <option value="">Service Provided</option>
                    {serviceProvided.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="tatizo_miundombinu" className="mb-1 p-1">Filter by Problems Infrastructure</label>
                  <select id="tatizo_miundombinu" className="form-control">
                    <option value="">Select Problem</option>
                    {tatizoMiundombinu.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3 mt-4 float-end">
                <button className="btn btn-primary text-white col-12" onClick={handleSearch}>Search <i className="fa fa-search" aria-hidden="true" /></button>
              </div>
              <div className="col-lg-3 mt-4 float-end">
                <button className="btn btn-success text-white col-12" onClick={Download}>Download CSV <i className="fa fa-download" aria-hidden="true" /></button>
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <p className="text-center">Loading...</p>
        ) : downloading ? (
          <div className="text-center">
            <p>Downloading...</p>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : data.length === 0 ? (
          <p className="text-center">No data available</p>
        ) : (
          <div className="container-fluid">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Name</th>
                  <th>Account Number</th>
                  <th>Phone</th>
                  <th>Meter Number</th>
                  <th>Data collector</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{((currentPage - 1) * 30) + index + 1}</td>
                    <td>{item.first_name} {item.middle_name} {item.sur_name}</td>
                    <td>{item.account_number}</td>
                    <td>{item.phone_number}</td>
                    <td>{item.meter_number}</td>
                    <td>{item.registrar_code}</td>
                    <td>
                      <Link to={`/admins/view-customer/${item.id}`} className="btn btn-info btn-sm me-2">View</Link>
                      <button className="btn btn-danger btn-sm" onClick={() => handleDeleteCustomer(item.id)}   disabled={!isAdmin}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="d-flex justify-content-center mt-3">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
              </li>
              {renderPageNumbers()}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default CustomerDetail;
