import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingBar from 'react-top-loading-bar';
import { Chart } from 'react-google-charts';
import { Link } from 'react-router-dom';

function Content() {
  const [data, setData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [totalCustomer, setTotalCustomer] = useState(0); // Declare totalCustomer state
  const [TotalLength, setTotalLength] = useState({ total_length_km: 0 });
  const token = localStorage.getItem('token');
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";

  useEffect(() => {
    const fetchTotalLength = async () => {
      try {
        const response = await axios.get('http://41.59.227.123:55005/api/get-total-pipeline', {
          params: {
            token: token,
            agency_code: agency_code
          }
        });
        setTotalLength(response.data); // Assuming the API returns the object with total_length_km
      } catch (error) {
        console.error('Error fetching total pipeline length:', error);
      }
    };
  
    fetchTotalLength();
  }, [token, agency_code]);
  

  useEffect(() => {
    const fetchData = () => {
      const token = localStorage.getItem('token');
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  
      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }
  
      setProgress(50);
  
      axios.get(`http://41.59.227.123:55005/api/home_page`, {
        params: {
          agency_code: agency_code,
          token: token
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        setData(responseData);
        const total_customer = responseData.totalCustomers || 0;
        setTotalCustomer(total_customer);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setProgress(100);
        setTimeout(() => setProgress(0), 300000);
      });
    };
  
    // Fetch data initially
    fetchData();
  
    // Fetch data every 5 minutes
    const interval = setInterval(fetchData, 600000);
  
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)} // Reset progress when loading finishes
      />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Home Page</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">    
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{totalCustomer}</h3>
                  <p>Mapped Customers</p>
                </div>
                <div className="icon">
                  <i className="fa fa-users" />
                </div>
                <Link to="/admins/customer-details" className="small-box-footer">Mapped Customers <i className="fas fa-arrow-circle-right" /></Link>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{TotalLength.total_length_km.toFixed(3)} Km</h3>
                  <p>Total Infrastructure</p>
                </div>
                <div className="icon">
                  <i className="fa fa-user" />
                </div>
                <Link to="/admins/water-network" className="small-box-footer">View all <i className="fas fa-arrow-circle-right" /></Link>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>OnProgress</h3>
                  <p>Revenue This Month</p>
                </div>
                <div className="icon">
                  <i className="fa fa-ruler-vertical" />
                </div>
                <a className="small-box-footer"> <i className="fas fa-arrow-circle-right" /></a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>OnProgress</h3>
                  <p>Total depts</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph" />
                </div>
                <a className="small-box-footer">View All <i className="fas fa-arrow-circle-right" /></a>
              </div>
            </div>
          </div>   

          <div className="row mx-auto">
            {/* Connection Status Graph */}
            <div className="col-md-6">
              <div className="card mt-6">
                <div className="card-header">Customer Connection Status</div>
                <div className="card-body">
                  <Chart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    data={[
                      ['Connection Status', 'Number of Customers'],
                      ...data?.connectionStatus?.map(status => [status.name, status.count]) || []
                    ]}
                    options={{
                      is3D: true,
                      colors: ['#FF0000', '#0096FF', '#006400', '#e39e09']
                    }}
                    rootProps={{ 'data-testid': '1' }}
                  />
                </div>
              </div>
            </div>

            {/* Meter Types Graph */}
            <div className="col-md-6">
              <div className="card mt-6">
                <div className="card-header">Customers Meter Types</div>
                <div className="card-body">
                  <Chart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    data={[
                      ['Meter Types', 'Number of Customers'],
                      ...data?.meterTypes?.map(meter => [meter.name, meter.count]) || []
                    ]}
                    options={{
                      is3D: true,
                      colors: ['#006400', '#e39e09','#FF0000']
                    }}
                    rootProps={{ 'data-testid': '2' }}
                  />
                </div>
              </div>
            </div>

            {/* Borehole Status Graph */}
            <div className="col-md-6">
              <div className="card mt-6">
                <div className="card-header">Boreholes</div>
                <div className="card-body">
                  <Chart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    data={[
                      ['Borehole Status', 'Number of Customers'],
                      ...data?.boreholeStatus?.map(borehole => [borehole.name, borehole.count]) || []
                    ]}
                    options={{
                      is3D: true,
                      colors: ['#ea1f1f','#006400']
                    }}
                    rootProps={{ 'data-testid': '3' }}
                  />
                </div>
              </div>
            </div>

            {/* Customer Categories Graph */}
            <div className="col-md-6">
              <div className="card mt-6">
                <div className="card-header">Customer Categories</div>
                <div className="card-body">
                  <Chart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    data={[
                      ['Customer Categories', 'Number of Customers'],
                      ...data?.customerTypes?.map(type => [type.name, type.count]) || []
                    ]}
                    options={{
                      is3D: true,
                      colors: ['#FF0000', '#ea1f1f', '#e39e09','#971fea','#e1ea1f','#00A36C','#0096FF']
                    }}
                    rootProps={{ 'data-testid': '7' }}
                  />
                </div>
              </div>
            </div>

            {/* Customer Problems Graph */}
            <div className="col-md-6">
              <div className="card mt-6">
                <div className="card-header">Customer Problems</div>
                <div className="card-body">
                  <Chart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    data={[
                      ['Customer Problems', 'Number of Customers'],
                      ...data?.problems?.map(problem => [problem.name, problem.count]) || []
                    ]}
                    options={{
                      is3D: true,
                      colors: ['#e39e09','#006400','#FF0000' ]
                    }}
                    rootProps={{ 'data-testid': '3' }}
                  />
                </div>
              </div>
            </div>

            {/* Infrastructure Issues Graph */}
            <div className="col-md-6">
              <div className="card mt-6">
                <div className="card-header">Infrastructure Issues</div>
                <div className="card-body">
                  <Chart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    data={[
                      ['Infrastructure Issues', 'Number of Customers'],
                      ...data?.infrastructureIssues?.map(issue => [issue.name, issue.count]) || []
                    ]}
                    options={{
                      is3D: true,
                      colors: ['#ea1f1f', '#006400', '#5F9EA0','#971fea','#ff8c00','#00ced1','#ff1493','#1f72ea','#971fea']
                    }}
                    rootProps={{ 'data-testid': '9' }}
                  />
                </div>
              </div>
            </div>

            {/* Customer Satisfaction Graph */}
            <div className="col-md-6">
              <div className="card mt-6">
                <div className="card-header">Customer Satisfaction</div>
                <div className="card-body">
                  <Chart
                    width={'100%'}
                    height={'auto'}
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    data={[
                      ['Customer Satisfaction', 'Number of Customers'],
                      ...data?.customerSatisfaction?.map(satisfaction => [satisfaction.name, satisfaction.count]) || []
                    ]}
                    options={{
                      is3D: true,
                      colors: ['#008000', '#FFFF00', '#FFA500', '#FF0000']
                    }}
                    rootProps={{ 'data-testid': '7' }}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
