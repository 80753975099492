import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

function ViewCustomerDetails() {
    const { id } = useParams();
    const [progress, setProgress] = useState(0);
    const [customerData, setCustomerData] = useState({});
    

    useEffect(() => {
        setProgress(50);
        const fetchCustomerDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";
                const response = await axios.get(`http://41.59.227.123:55005/api/get_customer_details/${id}`, {
                    params: {
                        agency_code: agency_code,
                        token: token,
                        id: id
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                const data = response.data;
                setCustomerData({
                    first_name: data.first_name,
                    middle_name: data.middle_name,
                    last_name: data.sur_name,
                    account_number: data.account_number,
                    phone_number: data.phone_number,
                    plot_number: data.plot_number,
                    connection_type: data.customer_type_name,
                    service_provided: data.service_provided_name,
                    customer_problem: data.customer_problem_name,
                    connection_pipe_size: data.connection_material_name,
                    connection_status: data.connection_status_name,
                    meter_type: data.meter_type_name,
                    meter_number: data.meter_number,
                    borehole: data.has_borehole ? "Yes" : "No",
                    water_source: data.water_source_name,
                    waste_water_connection: data.has_waste_water ? "Yes" : "No",
                    toilet_type: data.toilet_type_name,
                    toilet_users: data.toilet_user_number,
                    district: data.district_name,
                    zonal: data.zonal_name,
                    ward: data.ward_name,
                    street: data.street_name,
                    urban_poor: data.urban_poor_beneficiary,
                    serve_urban_poor: data.serve_urban_poor_name,
                    pipe_diameter: data.pipe_diameter_name,
                    tatizo_miundombinu: data.tatizo_miundombinu_name,
                    customer_id: data.id,
                    idadi_wakazi_kaya_name: data.idadi_wakazi_kaya_name,
                    location: data.location
                });
                setProgress(100);
            } catch (error) {
                setProgress(100);
                console.error('Error fetching customer details: ', error);
            }
        };
        fetchCustomerDetails();
    }, [id]);

    return (
        <div>
            <LoadingBar
                progress={progress}
                color='#29D'
                onLoaderFinished={() => setProgress(0)} // Reset progress when loading finishes
            />
            <div className="content-wrapper">
                <div className="col-12 mx-auto">
                    <h2>Customer Details</h2>
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th>First Name</th>
                                <td>{customerData.first_name}</td>
                            </tr>
                            <tr>
                                <th>Middle Name</th>
                                <td>{customerData.middle_name}</td>
                            </tr>
                            <tr>
                                <th>Last Name</th>
                                <td>{customerData.last_name}</td>
                            </tr>
                            <tr>
                                <th>Account Number</th>
                                <td>{customerData.account_number}</td>
                            </tr>
                            <tr>
                                <th>Phone Number</th>
                                <td>{customerData.phone_number}</td>
                            </tr>
                            <tr>
                                <th>Plot Number</th>
                                <td>{customerData.plot_number}</td>
                            </tr>
                            <tr>
                                <th>Connection Type</th>
                                <td>{customerData.connection_type}</td>
                            </tr>
                            <tr>
                                <th>Service Provided</th>
                                <td>{customerData.service_provided}</td>
                            </tr>
                            <tr>
                                <th>Customer Problem</th>
                                <td>{customerData.customer_problem}</td>
                            </tr>
                            <tr>
                                <th>Connection Pipe Size</th>
                                <td>{customerData.connection_pipe_size}</td>
                            </tr>
                            <tr>
                                <th>Pipe Diameter</th>
                                <td>{customerData.pipe_diameter}</td>
                            </tr>
                            <tr>
                                <th>Connection Status</th>
                                <td>{customerData.connection_status}</td>
                            </tr>
                            <tr>
                                <th>Meter Type</th>
                                <td>{customerData.meter_type}</td>
                            </tr>
                            <tr>
                                <th>Meter Number</th>
                                <td>{customerData.meter_number}</td>
                            </tr>
                            <tr>
                                <th>Borehole</th>
                                <td>{customerData.borehole}</td>
                            </tr>
                            <tr>
                                <th>Water Source</th>
                                <td>{customerData.water_source}</td>
                            </tr>
                            <tr>
                                <th>Urban Poor Beneficiary</th>
                                <td>{customerData.urban_poor}</td>
                            </tr>
                            <tr>
                                <th>Serve Urban Poor</th>
                                <td>{customerData.serve_urban_poor}</td>
                            </tr>
                            
                            <tr>
                                <th>Tatizo Miundombinu</th>
                                <td>{customerData.tatizo_miundombinu}</td>
                            </tr>
                            <tr>
                                <th>Waste Water Connection</th>
                                <td>{customerData.waste_water_connection}</td>
                            </tr>
                            <tr>
                                <th>Toilet Type</th>
                                <td>{customerData.toilet_type}</td>
                            </tr>
                            <tr>
                                <th>Toilet Users</th>
                                <td>{customerData.idadi_wakazi_kaya_name}</td>
                            </tr>
                            
                            <tr>
                                <th>Location</th>
                                <td><a href={`https://www.google.com/maps/place/${customerData.location}`} target="_blank" rel="noopener noreferrer">{customerData.location}</a></td>
                            </tr>
                            <tr>
                                <th>District</th>
                                <td>{customerData.district}</td>
                            </tr>
                            <tr>
                                <th>Zonal</th>
                                <td>{customerData.zonal}</td>
                            </tr>
                            <tr>
                                <th>Ward</th>
                                <td>{customerData.ward}</td>
                            </tr>
                            <tr>
                                <th>Street</th>
                                <td>{customerData.street}</td>
                            </tr>
                           
                        </tbody>
                    </table>
                   <div className="col-lg-12 mb-5">
                    <Link to={`/admins/edit-customer/${id}`}>
                        <button className="btn btn-primary btn-lg " >Edit Customer Details</button>
                    </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewCustomerDetails;
