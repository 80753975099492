import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import Select from 'react-select';

function WaterQualityStationRecords() {
  const [progress, setProgress] = useState(0);
  const [cleanWaterData, setCleanWaterData] = useState([]);
  const [wasteWaterData, setWasteWaterData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const token = localStorage.getItem('token');
  const { station_id, station_type, name } = useParams();
  const [categorieslist, setCategorieslist] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null); // Updated
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  useEffect(() => {
    fetchData();
  }, [station_type, page]);

  const fetchData = async () => {
    try {
      setProgress(50);
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      let url;
      if (station_type === "1") {
        url = `http://41.59.227.123:55005/api/clean_water_record`;
      } else if (station_type === "2") {
        url = `http://41.59.227.123:55005/api/sewage_water_record`;
      }

      const response = await axios.get(url, {
        params: {
          agency_code,
          token,
          station_id,
          page,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        if (station_type === "1") {
          setCleanWaterData(response.data.data);
        } else if (station_type === "2") {
          setWasteWaterData(response.data.data);
        }
        setPage(response.data.page);
        setTotalPages(response.data.totalPages);
        setTotalRecords(response.data.totalRecords);
      } else {
        if (station_type === "1") {
          setCleanWaterData([]);
        } else if (station_type === "2") {
          setWasteWaterData([]);
        }
        setTotalRecords(0);
      }

      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      if (station_type === "1") {
        setCleanWaterData([]);
      } else if (station_type === "2") {
        setWasteWaterData([]);
      }
      setTotalRecords(0);
      setProgress(100);
    }
  };

  const formatDate = (datetime) => {
    const date = new Date(datetime);
    return date.toLocaleDateString();
  };

  const formatTime = (datetime) => {
    const date = new Date(datetime);
    return date.toLocaleTimeString();
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    fetchListedCategories();
  }, []);

  const fetchListedCategories = async () => {
    try {
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";

      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      const response = await axios.get(`http://41.59.227.123:55005/api/measurmentCategoriesList`, {
        params: { agency_code, token },
        headers: { "Content-Type": "application/json" },
      });

      // Convert response data to react-select format
      const formattedCategories = response.data.map(category => ({
        value: category.id,
        label: category.name
      }));

      setCategorieslist(formattedCategories);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const handleSearch = () => {
    if (!selectedCategory || !fromDate || !toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill all fields (category, from date, and to date).',
      });
      return;
    }

    fetchFilteredData();
  };

  const fetchFilteredData = async () => {
    try {
      setProgress(50);
      const userString = localStorage.getItem("user");
      const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  
      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }
  
      // Construct the URL with the appropriate values
      const url = `/admins/water-quality-search/${station_id}/${station_type}/${selectedCategory.value}/${fromDate}/${toDate}/${name}`;
  
      // Redirect to the constructed URL
      window.location.href = url;
  
      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setTotalRecords(0);
      setProgress(100);
    }
  };

  const handleDeleteCustomer = (dataId) => {
    const token = localStorage.getItem('token');
    // Display confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this data. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        let deleteUrl;
        if (station_type === "1") {
          deleteUrl = 'http://41.59.227.123:55005/api/delete_clean_water_record';
        } else if (station_type === "2") {
          deleteUrl = 'http://41.59.227.123:55005/api/delete_sewage_water_record';
        }

        axios.delete(deleteUrl, { data: { dataId: dataId, token: token } })
          .then(response => {
            // Handle success
            Swal.fire({
              title: 'Deleted!',
              text: 'The data has been deleted.',
              icon: 'success'
            });
            // Update UI by fetching the data again
            fetchData();
          })
          .catch(error => {
            // Handle error
            console.error('Error deleting data:', error);
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete the data.',
              icon: 'error'
            });
          });
      }
    });
  };

  const getLinkPath = () => {
    if (station_type === "1") {
      return `/admins/water-clean-graphs/${station_id}/${name}`;
    } else if (station_type === "2") {
      return `/admins/water-sewage-graphs/${station_id}/${name}`;
    } else {
      return false; // Default path if station_type is not 1 or 2
    }
  };
  
  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
             
              <div className="col-lg-10">
                <form className="form-inline my-2 my-lg-0">
                <Select
                     
                    onChange={setSelectedCategory}
                    placeholder="Select Category"
                    options={categorieslist}
                    styles={{ container: (base) => ({ ...base, width: 250 }) }}
                   
                  />                  &nbsp; From &nbsp;
                  <input
                    className="form-control mr-sm-2"
                    type="date"
                    id="from_date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                  to &nbsp;
                  <input
                    className="form-control mr-sm-2"
                    type="date"
                    id="to_date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                  <button
                    className="btn btn-outline-primary my-2 my-sm-0"
                    type="button"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </form>
              </div>
              <div className="col-lg-2">
                <Link className="btn btn-md btn-success" to={getLinkPath()}>Show Graphs</Link>
              </div>
            </div>
          </div>
          {totalRecords === 0 ? (
            <center>
              <p className="h4">No records available</p>
            </center>
          ) : (
            <>
              {station_type === "1" && (
                <div className="col-lg-12 mb-2">
                  <p className="h6 text-primary text-center">{name} Station - Clean Water</p>
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th scope="col">TDS</th>
                        <th scope="col">pH</th>
                        <th scope="col">Turbidity</th>
                        <th scope="col">Colour</th>
                        <th scope="col">Electrical Conductivity</th>
                        <th scope="col">Residue Chlorine</th>
                        <th scope="col">Amonia and Iron</th>
                        <th scope="col">Dissolved Oxygen</th>
                        <th scope="col">Feacal Coliform</th>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cleanWaterData.map((record, index) => (
                        <tr key={index}>
                          <td>{record.tds}</td>
                          <td>{record.ph}</td>
                          <td>{record.turbidity}</td>
                          <td>{record.colour}</td>
                          <td>{record.electrical_conductivity}</td>
                          <td>{record.residue_chlorine}</td>
                          <td>{record.amonia_and_iron}</td>
                          <td>{record.dissolved_oxygen}</td>
                          <td>{record.feacal_coliform}</td>
                          <td>{formatDate(record.created_at)}</td>
                          <td>{formatTime(record.created_at)}</td>
                          <td><span className="fa fa-trash text-danger" onClick={() => handleDeleteCustomer(record.id)} ></span></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {station_type === "2" && (
                <div className="col-lg-12 mb-2">
                  <p className="h6 text-primary text-center">{name} Station - Waste Water</p>
                  <table className="table table-striped table-bordered table-hover">
  <thead>
    <tr>
      <th scope="col">BOD5</th>
      <th scope="col">COD</th>
      <th scope="col">DO</th>
      <th scope="col">pH</th>
      <th scope="col">TSS</th>
      <th scope="col">Colour</th>
      <th scope="col">Ammonium</th>
      <th scope="col">Nitrate</th>
      <th scope="col">Phosphate</th>
      <th scope="col">Phosphorus</th>
      <th scope="col">Total Coliform</th>
      <th scope="col">Feacal Coliform</th>
      <th scope="col">Oil and Grease</th>
      <th scope="col">Date</th>
      <th scope="col">Time</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    {wasteWaterData.map((record, index) => (
      <tr key={index}>
        <td>{record.bod5}</td>
        <td>{record.cod}</td>
        <td>{record.do}</td>
        <td>{record.ph}</td>
        <td>{record.tss}</td>
        <td>{record.colour}</td>
        <td>{record.ammonium}</td>
        <td>{record.nitrate}</td>
        <td>{record.phosphate}</td>
        <td>{record.phosphorus}</td>
        <td>{record.total_coliform}</td>
        <td>{record.feacal_coliform}</td>
        <td>{record.greece}</td>
        <td>{formatDate(record.created_at)}</td>
        <td>{formatTime(record.created_at)}</td>
        <td><span className="fa fa-trash text-danger" onClick={() => handleDeleteCustomer(record.id)}></span></td>
      </tr>
    ))}
  </tbody>
</table>

                </div>
              )}
              <div className="pagination">
                <button className="btn btn-md btn-primary" disabled={page <= 1} onClick={() => handlePageChange(page - 1)}>Previous</button>
                <span>Page {page} of {totalPages}</span>
                <button className="btn btn-md btn-primary" disabled={page >= totalPages} onClick={() => handlePageChange(page + 1)}>Next</button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default WaterQualityStationRecords;
