import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

function WaterLevels() {
  const [progress, setProgress] = useState(0);
  const [tanks, setTanks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem('token');
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";

  useEffect(() => {
    fetchTanks();
  }, [agency_code, token, page]);

  const fetchTanks = async () => {
    try {
      if (!agency_code || !token) {
        console.log("Agency code or token not found in localStorage");
        return;
      }

      setProgress(50);

      const response = await axios.get(`http://41.59.227.123:55005/api/level_tanks`, {
        params: {
          agency_code: agency_code,
          token: token,
          page: page
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      setTanks(response.data.data);
      setTotalPages(response.data.totalPages); // Assuming the backend sends totalPages
      setProgress(100);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setProgress(100);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  return (
    <div>
      <LoadingBar
        progress={progress}
        color='#29D'
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          {tanks.length === 0 ? (
            <div className="text-center mt-5">
              <h3>No connected tanks</h3>
            </div>
          ) : (
            tanks.map((tank, index) => {
              // Reverse the tank levels to ensure the oldest value comes first
              const sortedTankLevels = [...tank.tank_levels].reverse();
              const latestLevel = sortedTankLevels.length > 0 ? sortedTankLevels[sortedTankLevels.length - 1] : null;

              return (
                <div key={index} className="col-lg-12 mt-3 mb-3 p-2 card">
                  <h3 className="text-primary">{tank.tank_name}</h3>
                  <p><strong>Tank Capacity:</strong> {tank.tank_capacity}</p>
                  <div className="mt-4">
                    <h5>Water Level Trend</h5>
                    <Line 
                      data={{
                        labels: sortedTankLevels.map(level => new Date(level.created_at).toLocaleString()),
                        datasets: [
                          {
                            label: 'Water Level',
                            data: sortedTankLevels.map(level => level.value),
                            fill: true,
                            backgroundColor: 'rgba(0, 123, 255, 0.3)', // Water-like color
                            borderColor: 'rgba(0, 123, 255, 1)',
                            pointBackgroundColor: 'rgba(0, 123, 255, 1)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgba(0, 123, 255, 1)',
                            tension: 0.2, // Add this line to make the line less steep
                          }
                        ]
                      }} 
                    />
                  </div>
                  <div className="mt-4">
                    <h5>Latest Water Level Reading: 
                      {latestLevel ? `${latestLevel.value} (Recorded on ${new Date(latestLevel.created_at).toLocaleString()})` : 'N/A'}
                    </h5>
                  </div>
                </div>
              );
            })
          )}
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page - 1)}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  className={`page-item ${index + 1 === page ? 'active' : ''}`}
                  key={index}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default WaterLevels;
