import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import Swal from "sweetalert2";

function Technician() {
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [zoneOptions, setZoneOptions] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const token = localStorage.getItem("token");
  const userString = localStorage.getItem("user");
  const agency_code = userString ? JSON.parse(userString).agency_code : "User";
  const [searchType, setSearchType] = useState("");
  let isAdmin = false;

  if (userString) {
  const user = JSON.parse(userString);
  isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
  }

  useEffect(() => {
    fetchData();
    fetchLoggedInUser();
    fetchZones();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setProgress(30);
      const response = await axios.get("http://41.59.227.123:55005/api/technicians", {
        params: { page: currentPage, token: token, agency_code: agency_code, search: searchType }
      });
      
      if (response.data && response.data.data) {
        setData(response.data.data);
        setTotalPages(response.data.totalPages);
      } else {
        setData([]);
        setTotalPages(1);
      }
      
      setProgress(100);
    } catch (error) {
      console.error("Error fetching data:", error);
      setProgress(0);
    }
  };

  const fetchLoggedInUser = async () => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const user = JSON.parse(userString);
      setLoggedInUser(user);
    }
  };

  const fetchZones = async () => {
    try {
      const response = await axios.get('http://41.59.227.123:55005/api/listed_zones', {
        params: {
          agency_code: agency_code,
          token: token
        },
        headers: {
          "Content-Type": "application/json",
        }
      });
      setZoneOptions(response.data.data);
    } catch (error) {
      console.error('Error fetching zone data: ', error);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    if (!searchType.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Search field cannot be empty.',
      });
      return;
    }

    try {
      setProgress(30);
      const response = await axios.get("http://41.59.227.123:55005/api/search-technicians", {
        params: { page: 1, token: token, agency_code: agency_code, search: searchType }
      });
      
      if (response.data && response.data.data) {
        setData(response.data.data);
        setTotalPages(response.data.totalPages);
        setCurrentPage(1); // Reset to the first page of the search results
      } else {
        setData([]);
        setTotalPages(1);
      }
      
      setProgress(100);
    } catch (error) {
      console.error("Error fetching data:", error);
      setProgress(0);
    }
  };

  const addNew = () => {
    setSelectedUser({ full_name: "", email: "", zonal_id: "" });
    setSelectedZone("");
    setShowEditModal(true);
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setSelectedZone(user.zonal_id);
    setShowEditModal(true);
  };

  const handleDeleteData = (userId) => {
    console.log(userId);
    // Display confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this Detail. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform deletion operation with the item ID
        axios.delete('http://41.59.227.123:55005/api/delete-technician', { data: { userId:userId, token: token } })
          .then(response => {
            // Handle success
            // Show success message
            Swal.fire({
              title: 'Deleted!',
              text: 'The data has been deleted.',
              icon: 'success'
            });
            // Update UI by removing the deleted street from data state
            fetchData(); // Refresh streets after deletion
          })
          .catch(error => {
            // Handle error
            console.error('Error deleting data:', error);
            // Show error message
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete the data.',
              icon: 'error'
            });
          });
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
    setShowResetModal(false);
    setSelectedUser(null);
    setSelectedZone("");
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEditSubmit = async () => {
    const { full_name, email, zonal_id } = selectedUser;
  
    if (!full_name || !email || !selectedZone) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'All fields are required.',
      });
      return;
    }
  
    if (!validateEmail(email)) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please enter a valid email address.',
      });
      return;
    }
  
    try {
      if (selectedUser.id) {
        await axios.put(`http://41.59.227.123:55005/api/edit-technician/${selectedUser.id}`, {
          full_name: full_name,
          email: email,
          zonal_id: selectedZone,
          token: token,
          agency_code: agency_code
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Technician details updated successfully!',
        });
      } else {
        await axios.post("http://41.59.227.123:55005/api/add-technician", {
          full_name: full_name,
          email: email,
          zonal_id: selectedZone,
          token: token,
          agency_code: agency_code
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Technician added successfully!',
        });
      }
      fetchData();
      handleModalClose();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error === "Technician with this email already exists") {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Technician with this email already exists.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while saving the technician.',
        });
      }
      console.error("Error saving technician:", error);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Password Mismatch',
        text: 'New password and confirm password do not match.',
      });
      return;
    }
    try {
      await axios.put(`http://41.59.227.123:55005/api/update-technician-password/${selectedUser.id}`, {
        password: newPassword, token: token
      });
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Password reset successfully!',
      });
      handleModalClose();
    } catch (error) {
      console.error("Error resetting password:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to reset password.',
      });
    }
  };

  return (
    <div>
      <LoadingBar progress={progress} color="#29D" onLoaderFinished={() => setProgress(0)} />
      <div className="content-wrapper">
        <div className="col-12 mx-auto">
          <div className="col-lg-12 mt-3 mb-3 p-2 card">
            <div className="row">
              <div className="col-lg-4">
                <p className="h3 text-primary">Registered Technicians</p>
              </div>
              <div className="col-lg-5">
                <form className="form-inline my-2 my-lg-0" onSubmit={handleSearch}>
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    id="search_type"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchType}
                    onChange={(e) => setSearchType(e.target.value)}
                  />
                  <button className="btn btn-outline-primary my-2 my-sm-0" type="submit">Search</button>
                </form>
              </div>
              <div className="col-lg-3">
                <Button className="btn btn-md btn-success" onClick={addNew} disabled={!isAdmin}>
                  <span className="fa fa-plus"></span> Add new Record
                </Button>
              </div>
            </div>
          </div>
          {data.length === 0 ? (
            <center>
              <p className="h4">No technicians registered</p>
            </center>
          ) : (
            <div className="col-lg-10 mb-2">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Zone</th>
                    <th scope="col">Reset Password</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((user, index) => (
                    <tr key={index}>
                      <td>{user.full_name}</td>
                      <td>{user.email}</td>
                      <td>{user.zonal_name}</td>
                      <td>
                        <Button className="btn btn-warning" onClick={() => { setSelectedUser(user); setShowResetModal(true); }}
                          disabled={!isAdmin}>
                          Reset Password
                        </Button>
                      </td>
                      <td>
                        <Button
                          className="btn btn-primary"
                          onClick={() => handleEdit(user)}
                          disabled={loggedInUser && loggedInUser.email === user.email || !isAdmin}
                         
                        >
                          Edit
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          className="btn btn-danger"
                          onClick={() => handleDeleteData(user.id)}
                          disabled={loggedInUser && loggedInUser.email === user.email || !isAdmin}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <div className="d-flex justify-content-center mt-3">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li className={`page-item ${index + 1 === currentPage ? 'active' : ''}`} key={index}>
                    <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {/* Modal for adding/editing technician */}
      <Modal show={showEditModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedUser && selectedUser.id ? "Edit Technician" : "Add New Technician"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formUserName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={selectedUser ? selectedUser.full_name : ""}
                onChange={(e) => setSelectedUser({ ...selectedUser, full_name: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="formUserEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={selectedUser ? selectedUser.email : ""}
                onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group controlId="formZone">
              <Form.Label>Zone</Form.Label>
              <Form.Control
                as="select"
                id="zone"
                value={selectedZone}
                onChange={(e) => setSelectedZone(e.target.value)}
                required
              >
                <option value="">Select Zone</option>
                {zoneOptions.map(option => (
                  <option key={option.id} value={option.id}>{option.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for resetting password */}
      <Modal show={showResetModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formResetPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formResetPasswordConfirm">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleResetPassword}>
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Technician;
