import React, { useState, useEffect } from "react";
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function EditCustomers ()
{
        const { id } = useParams();
        const [progress, setProgress] = useState(0);
        const [zonalOptions, setZonalOptions] = useState([]);
        const [selectedZonal, setSelectedZonal] = useState('');
        const [wards, setWards] = useState([]);
        const [selectedWardId, setSelectedWardId] = useState('');
        const [streets, setStreets] = useState([]); // Modified line
        const [selectedStreetId, setSelectedStreetId] = useState('');
        const [customerStatus, setCustomerStatus] = useState([]); 
        const [customerTypes, setCustomerTypes] = useState([]); 
        const [customerProblems, setCustomerProblems] = useState([]); 
        const [serviceProvided, setServiceProvided] = useState([]);
        const [connectionPipe, setconnectionPipe] = useState([]);
        const [connectionSize, setconnectionSize] = useState([]);
        const [meterType, setmeterType] = useState([]);
        const [waterSource, setwaterSource] = useState([]);
        const [ToiletType, setToiletType] = useState([]);
        const [ToiletUsers, setToiletUsers] = useState([]);
        const [customerData, setCustomerData] = useState({});
        const [urbanPoor, setUrbanPoor] = useState([]);  // Initialize state for Urban Poor
        const [serveUrbanPoor, setServeUrbanPoor] = useState([]);  // Initialize state for Serve Urban Poor
        const [tatizoMiundombinu, setTatizoMiundombinu] = useState([]);  // Initialize state for Tatizo Miundombinu
       

        const userString = localStorage.getItem('user');
        let isAdmin = false;
      
      if (userString) {
        const user = JSON.parse(userString);
        isAdmin = user.user_type === 'admin'; // Check if the user type is 'admin'
      }

        
    useEffect(() => {
        const fetchZonalData = async () => {
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_zones', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setZonalOptions(response.data);
          } catch (error) {
            console.error('Error fetching zonal data: ', error);
          }
        };
    
        fetchZonalData();
      }, []);

      const getSelectedWards = async (event) => {
        const selectedZonalId = event.target.value;
        setSelectedZonal(selectedZonalId);
    
        try {
          const token = localStorage.getItem('token');
          const userString = localStorage.getItem("user");
          const agency_code = userString ? JSON.parse(userString).agency_code : "User";
    
          const response = await axios.get(`http://41.59.227.123:55005/api/get_wards`, {
            params: {
              agency_code: agency_code,
              token: token,
              selectedZonalId: selectedZonalId
            },
            headers: {
              "Content-Type": "application/json",
            }
          }); 
          setWards(response.data);
        } catch (error) {
          console.error('Error fetching wards data: ', error);
        }
      };
    
    
      const fetchStreets = async (event) => {
        const selectedWardId = event.target.value; // Use selected ward ID
        setSelectedWardId(selectedWardId);
        try {
          const token = localStorage.getItem('token');
          const userString = localStorage.getItem("user");
          const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
          const response = await axios.get(`http://41.59.227.123:55005/api/get_streets`, {
            params: {
              agency_code: agency_code,
              token: token,
              selectedWardId: selectedWardId // Pass selected ward ID
            },
            headers: {
              "Content-Type": "application/json",
            }
          }); 
          setStreets(response.data); // Update streets state
        } catch (error) {
          console.error('Error fetching streets data: ', error);
        }
      };

      useEffect(() => {
        const ToiletUsers = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_toilet_users', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setToiletUsers(response.data);
          } catch (error) {
            console.error('Error Meter Type data: ', error);
          }
        };
    
        ToiletUsers();
      }, []);

      useEffect(() => {
        const ToiletType = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_toilet_type', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setToiletType(response.data);
          } catch (error) {
            console.error('Error Meter Type data: ', error);
          }
        };
    
        ToiletType();
      }, []);

      useEffect(() => {
        const waterSource = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_water_source', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setwaterSource(response.data);
          } catch (error) {
            console.error('Error Meter Type data: ', error);
          }
        };
    
        waterSource();
      }, []);


      useEffect(() => {
        const meterType = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_meter_type', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setmeterType(response.data);
          } catch (error) {
            console.error('Error Meter Type data: ', error);
          }
        };
    
        meterType();
      }, []);

      useEffect(() => {
        const connectionPipe = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_connection_pipe', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setconnectionPipe(response.data);
          } catch (error) {
            console.error('Error fetching zonal data: ', error);
          }
        };
    
        connectionPipe();
      }, []);

      useEffect(() => {
        const connectionPipeSize = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_connection_size', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setconnectionSize(response.data);
          } catch (error) {
            console.error('Error fetching zonal data: ', error);
          }
        };
    
        connectionPipeSize();
      }, []);

      useEffect(() => {
        const urbanPoor = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_urban_poor', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setUrbanPoor(response.data);
          } catch (error) {
            console.error('Error fetching zonal data: ', error);
          }
        };
    
        urbanPoor();
      }, []);

      useEffect(() => {
        const ServeUbarnPoor = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_serve_urban_poor', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setServeUrbanPoor(response.data);
          } catch (error) {
            console.error('Error fetching zonal data: ', error);
          }
        };
    
        ServeUbarnPoor();
      }, []);

      useEffect(() => {
        const tatizo_miundombinu = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/matatizo_miundoMbinu', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setTatizoMiundombinu(response.data);
          } catch (error) {
            console.error('Error fetching zonal data: ', error);
          }
        };
    
        tatizo_miundombinu();
      }, []);
    
      useEffect(() => {
        const fetchCustomerStatus = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_connection_status', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setCustomerStatus(response.data);
          } catch (error) {
            console.error('Error fetching zonal data: ', error);
          }
        };
    
        fetchCustomerStatus();
      }, []);
    
      useEffect(() => {
        const fetchCustomertypes = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_customer_type', {
              params: {
                agency_code: agency_code,
                token: token
                
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setCustomerTypes(response.data);
          } catch (error) {
            console.error('Error fetching zonal data: ', error);
          }
        };
    
        fetchCustomertypes();
      }, []);
    
    
      useEffect(() => {
        const fetchCustomerProblems = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_customer_problems', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setCustomerProblems(response.data);
          } catch (error) {
            console.error('Error fetching zonal data: ', error);
          }
        };
        
        fetchCustomerProblems();
      }, []);
    
    
      useEffect(() => {
        const fetchServiceProvided = async () => { // Fixed function name
          try {
            const token = localStorage.getItem('token');
            const userString = localStorage.getItem("user");
            const agency_code = userString ? JSON.parse(userString).agency_code : "User";
      
            const response = await axios.get('http://41.59.227.123:55005/api/get_service_provided', {
              params: {
                agency_code: agency_code,
                token: token
              },
              headers: {
                "Content-Type": "application/json",
              }
            }); 
            setServiceProvided(response.data);
          } catch (error) {
            console.error('Error fetching zonal data: ', error);
          }
        };
        
        fetchServiceProvided();
      }, []);



      useEffect(() => {
        setProgress(50);
        const fetchCustomerDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const userString = localStorage.getItem("user");
                const agency_code = userString ? JSON.parse(userString).agency_code : "User";
                const response = await axios.get(`http://41.59.227.123:55005/api/get_customer_details/${id}`, {
                    params: {
                        agency_code: agency_code,
                        token: token,
                        id:id
                    },
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
                const data = response.data;
                setCustomerData({
                  first_name: data.first_name,
                  middle_name: data.middle_name,
                  last_name: data.sur_name, // changed from data.last_name to data.sur_name
                  account_number: data.account_number,
                  phone_number: data.phone_number,
                  plot_number: data.plot_number,
                  connection_type: data.customer_type_id, // changed from data.connection_type to data.customer_type_id
                  service_provided: data.service_provided,
                  customer_problem: data.problem_id, // changed from data.customer_problem to data.problem_id
                  connection_pipe: data.pipe_type, // changed from data.connection_pipe_size to data.connection_material_id
                  connection_status_id: data.connection_status_id,
                  meter_type: data.meter_type,
                  meter_number: data.meter_number,
                  borehole: data.has_borehole,
                  water_source: data.water_source, 
                  waste_water_connection: data.has_waste_water, // changed from data.waste_water_connection to data.has_waste_water
                  toilet_type: data.toilet_type,
                  toilet_users: data.toilet_user_number, // changed from data.toilet_users to data.toilet_user_number
                  zonal: data.zonal_id, // changed from data.zonal to data.zonal_id
                  ward: data.ward_id,
                  street: data.street_id,
                  customer_id: data.id,
                  pipe_diameter: data.pipe_diameter_id,
                  urbanpoor: data.urban_poor_id, // changed from data.urbanpoor to data
                  serve_urban_poor: data.serve_urban_poor_id,
                  tatizoMiundombinu: data.tatizo_miundombinu
                });
                setProgress(100);
            } catch (error) {
              setProgress(100);
                console.error('Error fetching customer details: ', error);
            }
        };
        fetchCustomerDetails();
    }, [id]);

    useEffect(() => {
      // Set the initial selected zonal value from customerData
      setSelectedZonal(customerData.zonal);
    }, [customerData.zonal]); // Trigger when customerData.zonal changes
    useEffect(() => {
      setSelectedWardId(customerData.ward);
      getSelectedWards({ target: { value: customerData.zonal } }); // Call getSelectedWards function with updated zonal value
    }, [customerData.ward, customerData.zonal]); // Trigger when customerData.ward or customerData.zonal changes
    
    useEffect(() => {
      setSelectedStreetId(customerData.street);
      fetchStreets({ target: { value: customerData.ward } }); // Call fetchStreets function with updated ward value
    }, [customerData.street, customerData.ward]); // Trigger when customerData.street or customerData.ward changes
    
      
    //code to update customer details

    const updateCustomerDetails = async () => {
      // Retrieving input values directly using getElementById
      const firstName = document.getElementById('edit_customer_first_name').value.trim();
      const middleName = document.getElementById('edit_customer_middle_name').value.trim();
      const lastName = document.getElementById('edit_customer_last_name').value.trim();
      const accountNumber = document.getElementById('edit_account_number').value.trim();
      const phoneNumber = document.getElementById('edit_phone_number').value.trim();
      const plotNumber = document.getElementById('edit_plot_number').value.trim();
      const customerCategory = document.getElementById('connection_types').value;
      const serviceProvided = document.getElementById('service_provided').value;
      const customerProblem = document.getElementById('customer_problem').value;
      const connectionPipeSize = document.getElementById('connection_pipe_size').value;
      const connectionStatus = document.getElementById('connection_status').value;
      const meterType = document.getElementById('meter_type').value;
      const meterNumber = document.getElementById('edit_meter_number').value.trim();
      const hasBorehole = document.getElementById('edit_borehole').value;
      const waterSource = document.getElementById('water_source').value;
      const wasteWaterConnection = document.getElementById('edit_waste_status').value;
      const toiletType = document.getElementById('toilet_type').value;
      const toiletUsersNumber = document.getElementById('number_toilet_users').value;
      const connection_pipe_material = document.getElementById('connection_pipe_material').value;
      const tatizo_miundombinu = document.getElementById('tatizo_miundombinu').value;
      const serve_urban_poor = document.getElementById('serve_urban_poor').value;
      const urban_poor = document.getElementById('urban_poor').value;
      const zonal = selectedZonal;
      const ward = selectedWardId;
      const street = selectedStreetId;
  
      // Validation to check if any of the fields are empty
      if (!firstName || !middleName || !lastName || !accountNumber || !phoneNumber ||
          !plotNumber || !customerCategory || !serviceProvided || !customerProblem ||
          !connectionPipeSize || !connectionStatus || !meterType || !meterNumber ||
          !hasBorehole || !waterSource || !wasteWaterConnection || !toiletType || !serve_urban_poor || !urban_poor ||
          !toiletUsersNumber || !zonal || !ward || !street || !connection_pipe_material || !tatizo_miundombinu) {
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'All fields must be filled out.',
          });
          return; // Stop the function if any field is empty
      }
  
      try {
          const token = localStorage.getItem('token');
          const agency_code = JSON.parse(localStorage.getItem("user")).agency_code;
          
          // Sending PUT request to update customer details
          const response = await axios.put(`http://41.59.227.123:55005/api/update-customer-details/${id}`, {
              firstName,
              middleName,
              lastName,
              accountNumber,
              phoneNumber,
              plotNumber,
              customerCategory,
              serviceProvided,
              customerProblem,
              connectionPipeSize,
              connectionStatus,
              meterType,
              meterNumber,
              hasBorehole,
              waterSource,
              wasteWaterConnection,
              toiletType,
              toiletUsersNumber,
              zonal,
              ward,
              street,
              token,
              agency_code,
              connection_pipe_material,
              tatizo_miundombinu,
              serve_urban_poor,
              urban_poor,
              id
          }, {
              headers: {
                  'Content-Type': 'application/json'
              }
          });
  
          // Handling the response from the server
          if (response.status === 200) {
              Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Customer details updated successfully!',
              });
          } else {
              throw new Error('Failed to update customer details');
          }
      } catch (error) {
          Swal.fire({
              icon: 'error',
              title: 'Failed to Update',
              text: error.response?.data?.message || 'An error occurred while updating the customer details.',
          });
      }
  };
  
  

    return(
        <div>
        <LoadingBar
          progress={progress}
          color='#29D'
          onLoaderFinished={() => setProgress(0)} // Reset progress when loading finishes
        />
        <div className="content-wrapper">
        <div className="col-12 mx-auto">
            
       {/* add activity popup modal starts here*/}
{/*pop up modal here*/}

  
      <div className="modal-body col-8">
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Customer First Name</label>
          <input type="text" className="form-control" id="edit_customer_first_name"  defaultValue={customerData.first_name} />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Customer Middle Name</label>
          <input type="text" className="form-control" id="edit_customer_middle_name" defaultValue={customerData.middle_name} />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Customer Last Name</label>
          <input type="text" className="form-control" id="edit_customer_last_name" defaultValue={customerData.last_name} />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Account number</label>
          <input type="text" className="form-control" id="edit_account_number" defaultValue={customerData.account_number} />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Phone number</label>
          <input type="text" className="form-control" id="edit_phone_number" defaultValue={customerData.phone_number} />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Plot number</label>
          <input type="text" className="form-control" id="edit_plot_number" defaultValue={customerData.plot_number} />
        </div>
        <div className="form-group">
              <label htmlFor="connection_types">Customer Category</label>
              <select
                id="connection_types"
                className="form-control"
                value={customerData.connection_type}
                onChange={(e) => setCustomerData({ ...customerData, connection_type: e.target.value })}
              >
                <option value="">Customer Category</option>
                {customerTypes.map(option => (
                  <option key={option.id} value={option.id}>{option.name}</option>
                ))}
              </select>
            </div>

        <div className="form-group">
          <label htmlFor="service_provided">Service provided</label>
          <select
            id="service_provided"
            className="form-control"
            value={customerData.service_provided} // Set the value attribute to the customerData value
            onChange={(e) => setCustomerData({ ...customerData, service_provided: e.target.value })} // Update customerData on change
          >
            <option value="">Service Provided</option>
            {serviceProvided.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option> // Remove selected attribute
            ))}
          </select>
        </div>

        <div className="form-group">
            <label htmlFor="customer_problem">Client has problem</label>
            <select
              id="customer_problem"
              className="form-control"
              value={customerData.customer_problem}
              onChange={(e) => setCustomerData({ ...customerData, customer_problem: e.target.value })}
            >
              <option value="">Customer problems</option>
              {customerProblems.map(option => (
                <option key={option.id} value={option.id}>{option.name}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
              <label htmlFor="connection_pipe_material">Connection pipe material</label>
              <select
                id="connection_pipe_material"
                className="form-control"
                value={customerData.connection_pipe}
                onChange={(e) => setCustomerData({ ...customerData, connection_pipe_type: e.target.value })}
              >
                <option value="">Choose Connection Pipe</option>
                {connectionPipe.map(option => (
                  <option key={option.id} value={option.id}>{option.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="connection_status">Connection status</label>
              <select
                id="connection_status"
                className="form-control"
                value={customerData.connection_status_id}
                onChange={(e) => setCustomerData({ ...customerData, connection_status_id: e.target.value })}
              >
                <option value="">Connection Status</option>
                {customerStatus.map(option => (
                  <option key={option.id} value={option.id}>{option.name}</option>
                ))}
              </select>
            </div>

       
        <div className="form-group">
          <label htmlFor="meter_type">Meter Type</label>
          <select
            id="meter_type"
            className="form-control"
            value={customerData.meter_type}
            onChange={(e) => setCustomerData({ ...customerData, meter_type: e.target.value })}
          >
            <option value="">Meter Type</option>
            {meterType.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Meter number</label>
          <input type="text" className="form-control" id="edit_meter_number" defaultValue={customerData. meter_number} />
        </div>
        <div className="form-group">
              <label htmlFor="edit_borehole">Has Borehole</label>
              <select
                id="edit_borehole"
                className="form-control"
                value={customerData.borehole}
                onChange={(e) => setCustomerData({ ...customerData, borehole: e.target.value })}
              >
                <option value={1}>Yes</option>
                <option value={2}>No</option>
                <option value={17}>None</option>
              </select>
            </div>

            <div className="form-group">
                <label htmlFor="water_source">Water Source</label>
                <select
                  id="water_source"
                  className="form-control"
                  value={customerData.water_source}
                  onChange={(e) => setCustomerData({ ...customerData, water_source: e.target.value })}
                >
                  <option value="">Choose Water Source</option>
                  {waterSource.map(option => (
                    <option key={option.id} value={option.id}>{option.name}</option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                  <label htmlFor="edit_waste_status">Waste Water Connection</label>
                  <select
                    id="edit_waste_status"
                    className="form-control"
                    value={customerData.waste_water_connection}
                    onChange={(e) => setCustomerData({ ...customerData, waste_water_connection: e.target.value })}
                  >
                    <option value={1}>Connected</option>
                    <option value={2}>Not Connected</option>
                    <option value={17}>NA</option>
                  </select>
                </div>

                <div className="form-group">
                    <label htmlFor="toilet_type">Toilet type</label>
                    <select
                      id="toilet_type"
                      className="form-control"
                      value={customerData.toilet_type}
                      onChange={(e) => setCustomerData({ ...customerData, toilet_type: e.target.value })}
                    >
                      <option value="">Choose Toilet Type</option>
                      {ToiletType.map(option => (
                        <option key={option.id} value={option.id}>{option.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                        <label htmlFor="number_toilet_users">Toilet users number</label>
                        <select
                          id="number_toilet_users"
                          className="form-control"
                          value={customerData.toilet_users}
                          onChange={(e) => setCustomerData({ ...customerData, toilet_users: e.target.value })}
                        >
                          <option value="">Choose number of users</option>
                          {ToiletUsers.map(option => (
                            <option key={option.id} value={option.id}>{option.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                            <label htmlFor="connection_pipe_size">Connection pipe size</label>
                            <select id="connection_pipe_size" className="form-control" value={customerData.pipe_diameter} onChange={(e) => setCustomerData({ ...customerData, pipe_diameter: e.target.value })}>
                                <option value="">Choose Connection Pipe size</option>
                                {connectionSize.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="urban_poor">Urban Poor Beneficiary</label>
                            <select id="urban_poor" className="form-control" value={customerData.urbanpoor}  onChange={(e) => setCustomerData({ ...customerData, urbanpoor: e.target.value })}>
                                <option value="">Select Urban Poor Beneficiary</option>
                                {urbanPoor.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="serve_urban_poor">Serve Urban Poor</label>
                            <select id="serve_urban_poor" className="form-control" value={customerData.serve_urban_poor} onChange={(e) => setCustomerData({ ...customerData, serve_urban_poor: e.target.value })}>
                                <option value="">Select Serve Urban Poor</option>
                                {serveUrbanPoor.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="tatizo_miundombinu">Tatizo Miundombinu</label>
                            <select id="tatizo_miundombinu" className="form-control" value={customerData.tatizoMiundombinu} onChange={(e) => setCustomerData({ ...customerData, tatizoMiundombinu: e.target.value })}>
                                <option value="">Select Tatizo Miundombinu</option>
                                {tatizoMiundombinu.map(option => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>

        <div className="form-group">
  <label htmlFor="exampleInputEmail1">Zonal</label>
  <select
    id="zonalSelect"
    className="form-control"
    value={selectedZonal}
    onChange={(event) => {
      setSelectedZonal(event.target.value);
      getSelectedWards(event); // Call getSelectedWards function
    }}
  >
    <option value="">Select Zonal</option>
    {zonalOptions.map(option => (
      <option key={option.id}  value={option.id}>{option.name}</option>
    ))}
  </select>
</div>
<div className="form-group">
  <label htmlFor="exampleInputEmail1">Ward</label>
  <select
    id="wardSelect"
    className="form-control"
    value={selectedWardId}
    onChange={(event) => {
      setSelectedWardId(event.target.value);
      fetchStreets(event); // Call fetchStreets function
    }}
  >
    <option value="">Select Ward</option>
    {wards.map(option => (
      <option key={option.id} value={option.id} >{option.name}</option>
    ))}
  </select>
</div>
<div className="form-group">
  <label htmlFor="exampleInputEmail1">Street</label>
  <select
    id="streetSelect"
    className="form-control"
    value={selectedStreetId}
    onChange={(event) => setSelectedStreetId(event.target.value)} // Update selectedStreetId state
  >
    <option value="">Select Streets</option>
    {streets.map(option => (
      <option key={option.id} value={option.id} >{option.name}</option>
    ))}
  </select>
</div>

        <div className="modal-footer">
          <button id="update_client" type="submit" onClick={updateCustomerDetails} className="btn btn-md btn-success" disabled={!isAdmin}>Update Customer Details</button>
        </div>
      </div>
    </div>
  </div>



         </div>   
    )
}
export default EditCustomers;